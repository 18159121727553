import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'

type IStyledCreateMeal = {
  isOverCurrent: boolean
}
export const StyledCreateMeal = styled['div']<IStyledCreateMeal>`
  border-radius: 10px;
  padding: 24px 18px;
  margin: 20px;
  border: 2px dashed ${COLORS.grey2};
  display: flex;
  align-items: center;
  transition:.3s ease-in-out;
  ${({ isOverCurrent }) => isOverCurrent ? 'transform: scale(1.01);' : ''}
  cursor: pointer;
  .round {
    background: ${COLORS.primary};
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 50%;
  }
`
