import { FC, useState } from 'react'

import { Text } from 'components'

import { StyledSearchRow } from './core/searchRow.styles'
import { IProps, ItemTypes } from './core/searchRow.types'
import { Tag } from 'components/Tag'
import { useDrag } from 'react-dnd'
import { Tooltip } from 'antd'
import Check from 'shared/assets/check.svg'
import { HelpCircle } from 'react-feather'
import { addFood, changeSidebarHidden } from 'pages/Program/core/programSlice'
import { useAppDispatch } from 'shared/hooks/store'

export const SearchRow: FC<IProps> = props => {
  const { emoji, title, description, stats, data } = props
  const dispatch = useAppDispatch()
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.FOOD,
      item: data,
      collect: monitor => ({
        isDragging: !!monitor.isDragging()
      })
    }),
    [data]
  )
  const [toggleDetails, setToggleDetails] = useState(false)
  return (
    <StyledSearchRow
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move'
      }}
      ref={drag}
      onClick={() => {
        if (window.innerWidth < 600) {
          dispatch(addFood({ food: data, idMeal: null }))
          dispatch(changeSidebarHidden({ visible: true }))
        }
      }}
    >
      <div className="header">
        <div className="icon">{emoji}</div>
        <div className="content-row">
          <div className="f f-jc-space-b f-ai-start">
            {title}{' '}
            {data.verified && (
              <Tooltip placement="right" title="Cela certifie que les nutriments sont fiables">
                <img className="verif" src={Check} />
              </Tooltip>
            )}
          </div>
          <Text>{description}</Text>
        </div>
      </div>
      <div className="stats">
        <Tag type="c" value={stats.calories} />
        <Tag type="p" value={stats.proteines} />
        <Tag type="g" value={stats.glucides} />
        <Tag type="l" value={stats.lipides} />
      </div>
      <div onClick={() => setToggleDetails(!toggleDetails)} className="toggle-more">
        Info complémentaires
      </div>
      {toggleDetails && (
        <div className="table-more-details">
          <div className="line">
            <div className="name">Calories</div> <div className="number">{data['energy-kcal_100g'] || '-'}</div>
          </div>
          <div className="line">
            <div className="name">Protéines</div> <div className="number">{data['proteins_100g'] || '-'}</div>
          </div>
          <div className="line">
            <div className="name">Total glucides</div> <div className="number">{data['carbohydrates_100g'] || '-'}</div>
          </div>
          <div className="line subline">
            <div className="name">Fibres alimentaire</div> <div className="number">{data['fiber_100g'] || '-'}</div>
          </div>
          <div className="line subline">
            <div className="name">Sucres</div> <div className="number">{data['sugars_100g'] || '-'}</div>
          </div>
          <div className="line subline">
            <div className="name">Polyols</div> <div className="number">{data['polyols_100g'] || '-'}</div>
          </div>
          <div className="line">
            <div className="name">Total lipides</div> <div className="number">{data['fat_100g'] || '-'}</div>
          </div>
          <div className="line subline">
            <div className="name">Acides gras saturés</div>{' '}
            <div className="number">{data['saturated-fat_100g'] || '-'}</div>
          </div>
          <div className="line subline">
            <div className="name">Acides gras trans</div> <div className="number">{data['trans-fat_100g'] || '-'}</div>
          </div>
          <div className="line subline">
            <div className="name">Acides gras polyinsaturés</div>{' '}
            <div className="number">{data['polyunsaturated-fat_100g'] || '-'}</div>
          </div>
          <div className="line subline">
            <div className="name">Acides gras monoinsaturés</div>{' '}
            <div className="number">{data['monounsaturated-fat_100g'] || '-'}</div>
          </div>
          <div className="line">
            <div className="name">Cholestérol</div> <div className="number">{data['cholesterol_100g'] || '-'}</div>
          </div>
          <div className="line">
            <Tooltip
              placement="right"
              title="Électrolyte qui favorise l'équilibre des liquides et le maintien de la pression artérielle."
            >
              <div className="name">
                Sodium <HelpCircle height={17} color="#1b1b1b" />
              </div>{' '}
            </Tooltip>
            <div className="number">{data['sodium_100g'] || '-'}</div>
          </div>
          <div className="line">
            <Tooltip
              placement="right"
              title="Il est nécessaire à la vie. Il participe au maintien de l'hydratation de l'organisme et à l'équilibre de la tension artérielle."
            >
              <div className="name">
                Sel <HelpCircle height={17} color="#1b1b1b" />
              </div>{' '}
            </Tooltip>
            <div className="number">{data['salt_100g'] + 'g' || '-'}</div>
          </div>
          <div className="line">
            <Tooltip
              placement="right"
              title="Elle favorise la fonction immunitaire et aide à l'absorption du calcium et à la croissance des os."
            >
              <div className="name">
                Vitamine D <HelpCircle height={17} color="#1b1b1b" />
              </div>{' '}
            </Tooltip>
            <div className="number">{data['vitamin-d_100g'] || '-'}</div>
          </div>
          <div className="line">
            <Tooltip
              placement="right"
              title="Il est nécessaire pour une structure et une fonction appropriées des os et des dents. Aide à la fonction musculaire et à la contraction des vaisseaux sanguins."
            >
              <div className="name">
                Calcium <HelpCircle height={17} color="#1b1b1b" />
              </div>{' '}
            </Tooltip>
            <div className="number">{data['calcium_100g'] || '-'}</div>
          </div>
          <div className="line">
            <Tooltip
              placement="right"
              title="Le fer aide à fournir de l'oxygène aux muscles et à la création de certaines hormones."
            >
              <div className="name">
                Fer <HelpCircle height={17} color="#1b1b1b" />
              </div>{' '}
            </Tooltip>
            <div className="number">{data['iron_100g'] || '-'}</div>
          </div>
          <div className="line">
            <Tooltip
              placement="right"
              title="Le potassium est une électrolyte qui maintient l'état des liquides dans les cellules et contribue à la transmission nerveuse et à la fonction musculaire."
            >
              <div className="name">
                Potassium <HelpCircle height={17} color="#1b1b1b" />
              </div>{' '}
            </Tooltip>
            <div className="number">{data['potassium_100g'] || '-'}</div>
          </div>
          <div className="line">
            <Tooltip
              placement="right"
              title="La vitamine A est nécessaire à la vision et au fonctionnement des organes."
            >
              <div className="name">
                Vitamine A <HelpCircle height={17} color="#1b1b1b" />
              </div>{' '}
            </Tooltip>
            <div className="number">{data['vitamin-a_100g'] || '-'}</div>
          </div>
          <div className="line">
            <Tooltip
              placement="right"
              title="La vitamine C est nécessaire à la création de neurotransmetteurs et de collagène, la principale protéine de la peau."
            >
              <div className="name">
                Vitamine C <HelpCircle height={17} color="#1b1b1b" />
              </div>
            </Tooltip>{' '}
            <div className="number">{data['vitamin-c_100g'] || '-'}</div>
          </div>
          <div className="line">
            <Tooltip
              placement="right"
              title="Va aider à plus de 300 réactions enzymatiques, y compris la régulation de la pression artérielle."
            >
              <div className="name">
                Magnesium <HelpCircle height={17} color="#1b1b1b" />
              </div>
            </Tooltip>
            <div className="number">{data['magnesium_100g'] || '-'}</div>
          </div>
        </div>
      )}
    </StyledSearchRow>
  )
}
