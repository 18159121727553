import { Text } from 'components/Text'
import { FC } from 'react'
import { FontFamilies, FontSizes, FontWeights } from 'shared/style/constants'
import { StyledFoodRowPublic } from './core/foodRow.styles'
import { IProps } from './core/foodRow.types.'

export const FoodRowPublic: FC<IProps> = props => {
  const { food } = props

  return (
    <StyledFoodRowPublic>
      <div className="header">
        <div className="overall">
          <div className="head">
            <div className="name">
              <Text className="product_name" weight={FontWeights.BOLD} font={FontFamilies.INTER}>
                {food.food.product_name}
              </Text>
              <Text
                className="product_name"
                size={FontSizes.SMALL}
                weight={FontWeights.MEDIUM}
                font={FontFamilies.INTER}
              >
                {food.size * food.quantity}g
              </Text>
            </div>
          </div>
        </div>
      </div>
    </StyledFoodRowPublic>
  )
}
