import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'

type IStyledIndex = {
  isOver: boolean
}

export const StyledIndex = styled['div']<IStyledIndex>`
  .header-program {
    display: flex;
    justify-content: space-between;
  }

  .line-stat {
    margin-top: 10px;
  }

  .ant-progress-outer {
    width: 50%;
  }

  .add {
    display:none;
    @media screen and (max-width: 600px) {
      display: flex;
      position: absolute;
      width: 54px;
      height: 54px;
      background: ${COLORS.primary};
      border-radius: 50%;
      color: #fff;
      bottom: 20px;
      left: 50%;
      margin-left:-22px;
      align-items: center;
      font-size: 28px;
      justify-content: center;
      box-shadow: 0px 2px 8px rgb(34 34 87 / 5%);
    }
  }

  .save-program {
    display: none;
    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      padding: 10px;
      z-index: 11;
      border-radius: 7px;
      box-shadow: 0px 2px 8px rgb(34 34 87 / 5%);
      position: absolute;
      right: 20px;
      bottom: 20px;
      background: ${COLORS.primary};
      color: #fff;
      font-size: 16px;
      font-weight: 600;
       > svg {
         margin-right: 5px;
       }
    }
  }

  .box {
    padding: 28px;
    border-radius: 10px;
    margin: 24px 24px 0 24px;
    background: ${COLORS.grey1};
    @media screen and (max-width: 600px) {
      div {
        flex-wrap: wrap;
      }
      .nowrap {
        flex-wrap: nowrap;
        flex:1;
      }
      p {
        width: 100%;
        margin-bottom: 10px;
      }
      .ml-3 {
        margin:0;
      }
      .f.f-jc.center button {
        display: none;
      }
      margin: 0;

    }
  }

  .program {
    flex:1;
  }

  .stats {
    flex:1;
  }

  .meal-list {
    width: auto;
    min-height: calc(100vh - 72px - 120px);

    margin: 24px;
    border-radius: 10px;
    background: ${COLORS.grey1};
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 600px) {
      margin: 0;
      background: transparent;
    }
    .placeholder {
      ${({isOver}) => isOver ? 'opacity: 0.5;' : ''}
      margin: 20px;
      height: calc(100%- 50px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      border-radius: 10px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='4' stroke-dasharray='20%2c 22' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
    }
    
  }


  .see {
    border-radius: 10px;
    background: ${COLORS.primary};
    padding: 10px;
    height: 42px;
    cursor:pointer;
  }

  .center {
    align-items: center;
  }

  .stats {

    .list {
      display: flex;
      justify-content:space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 600px) {
        .box {
          width: 100%;
          background:#fff;
        }
      }
    }

   .round {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      border-radius: 50%;
      margin-right: 6px;
  }

  .line-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .progress {
      margin: 0 18px;
      border-radius: 10px;
      height: 24px;
      flex:1;
      position: relative;
  }

  .complete-line {
      position: absolute;
      border-radius: 10px;
      height: 24px;
      left:0;
      top:0;
      bottom: 0;
  }

  .percentage {
      font-family: "Poppins";
      font-size: 20px;
      width: 40px;
      text-align: right;
  }

  .box {
      padding: 24px;
      margin:0;
      width: 49%;
      background: #fff;
      border-radius: 10px;
      margin-top: 14px;
      @media screen and (max-width: 600px) {
          background: ${COLORS.grey1};
          border-radius: 0;
      }
  }

  .calories {
      flex:1;
      .bg {
          background: #E74C3C30;
      }
      .complete-line {
          background: #E74C3C;
      }
  }
  .proteins {
      flex:1;
      .bg {
          background: #3498DB30;
      }
      .complete-line {
          background: #3498DB;
      }
  }
  .fat {
      flex:1;
      .bg {
          background: #1ABC9C30;
      }
      .complete-line {
          background: #1ABC9C;
      }
  }
  .carbs {
      flex:1;
      .bg {
          background: #9B59B630;
      }
      .complete-line {
          background: #9B59B6;
      }
  }

  .p {
      background: #3498DB;
  }
  .c {
      background: #E74C3C;
  }
  .l {
      background: #1ABC9C;
  }
  .g {
      background: #9B59B6;
  }
  }
`