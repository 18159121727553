import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import programReducer from '../pages/Program/core/programSlice'
import goalReducer from '../pages/Index/core/goalSlice'
import userReducer from './user/userSlice'
import programPublicReducer from '../pages/ProgramPublic/core/programPublicSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    program: programReducer,
    goal: goalReducer,
    programPublic: programPublicReducer
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
