import styled from 'styled-components'

import { COLORS,  } from 'shared/style/constants'

export const StyledHeader = styled('div')`
  z-index: 1;
  background: ${COLORS['white']};
  border-bottom: 1px solid ${COLORS.grey2};


  .logo {
    img {
      height: 48px;
    }
    .text {
        font-size: 20px;
        font-family:"Poppins";
        font-weight: 500;
        color: ${COLORS.primary};
    }
  }

  .profile {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .navbar-responsive {
    display: none;
    @media screen and (max-width: 600px) {
      display: flex;
      position: fixed;
      width: 80vw;
      z-index: 11111;
      right: 0;
      top: 81px;      
      background: #fff;
      bottom:0;
      transition: .3s ease-in-out;
      transform: translateX(100%);
      &.active {
        transform: translateX(0%);
      }
      .close {
        position: absolute;
        top:10px;
        right:22px;
      }
      .nav-link {
        padding: 15px;
        font-family: "Poppins";
        font-size: 18px;
        border-radius: 10px;
        color: #000;
        margin: 0 10px;
        &:hover, &.active {
          color: ${COLORS.primary};
        }
      }
    }
  }
    
  .navbar {
    display: flex;
    @media screen and (max-width: 600px) {
      display: none;
    }
    .nav-link {
      padding: 15px;
      font-family: "Poppins";
      font-size: 18px;
      color: ${COLORS.primary};
      border-radius: 10px;
      margin: 0 10px;
      &:hover, &.active {
        background: ${COLORS.primary};
        color: #fff;
      }
    }
  }

  .icon-menu {
    display: none;
    @media screen and (max-width: 600px) {
      display:block;
    }
  }
`
