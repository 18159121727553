import { Button, Text, TextInput } from 'components'
import { FC, useEffect, useState } from 'react'
import { StyledLogin } from './core/login.styles'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import { Map } from 'react-feather'
import Logo from 'shared/assets/logo.svg'
import { COLORS, Colors, FontFamilies, FontWeights } from 'shared/style/constants'
import { Link, useNavigate } from 'react-router-dom'
import { signIn, userSelector } from 'shared/user/userSlice'

const Login: FC = () => {
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showError, setShowError] = useState(false)
  const { isSuccess, isError } = useAppSelector(userSelector)
  const navigate = useNavigate()

  function handleSubmit() {
    dispatch(signIn({ email, password }))
  }

  useEffect(() => {
    if (isSuccess && email.length > 3 && password.length > 3) navigate('/')
    if (isError) setShowError(true)
  }, [isSuccess, isError])

  return (
    <StyledLogin>
      <div className="left-side">
        <div className="box">
          <div className="logo f f-center">
            <img src={Logo} className="mr-1" />
            <div className="text">NutriPlan</div>
          </div>
          {showError && <div className="error mt-3">Les identifiants sont incorrects</div>}
          <TextInput placeholder="Email" className="mt-3" value={email} name="email" type="email" setValue={setEmail} />
          <TextInput
            className="mt-1"
            value={password}
            name="password"
            type="password"
            placeholder="Mot de passe"
            setValue={setPassword}
          />
          <div className="mt-4 f f-jc-space-b">
            <Button
              onClick={() => handleSubmit()}
              className="w-100"
              mainColor={Colors.PRIMARY}
              secondaryColor={Colors.WHITE}
            >
              Se connecter
            </Button>
          </div>
          <div className="not-register">
            Pas encore inscrit ?{' '}
            <Link className="link" to="/register">
              S&apos;inscrire
            </Link>
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="box-present">
          <div className="title">Créer votre programme alimentaire simplement</div>
          Grâce à cette outil vous pourrez tracker vos calories ainsi que vos macro-nutriments pour vous permettre
          d’atteindre vos objectifs
        </div>
      </div>
    </StyledLogin>
  )
}

export default Login
