import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'


export const StyledIndex = styled['div']`
    .container {
        width: 1200px;
        margin: auto;
        @media screen and (max-width: 600px) {
            width: 100%;
        }

        .ant-col {
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
    }

    .group-btn {
        width: 50%;
        margin: auto;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    .list-activity {
        display: flex;
        justify-content: space-between;
        > div {
            margin-top: 0px;
            flex:1;
            margin-right: 5px;
            margin-left: 5px;
            @media screen and (max-width: 600px) {
                margin: 0px 0 15px 0;
                min-width: 100%;
                
            }
            button {
                width: 100%;
            }
            p {
                color: #7a7a7a;
                margin-top: 10px;
            }
        }
        > div:first-child {
            margin-left: 0px;
        }
        > div:last-child {
            margin-left: 0px;
        }
        @media screen and (max-width: 600px) {
            flex-wrap: wrap;
        }
    }

    .box {
        padding: 34px;
        background: ${COLORS.grey1};
        margin-top: 20px;
        border-radius: 10px;
        min-height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ant-input-group-wrapper {
        width: 38%;
        height: 50px;
        input {
            height: 50px;
            font-size: 21px;
        }
        @media screen and (max-width: 600px) {
            width: 50%;
        }
    }

    .question {
        text-align: center;
        font-family: "Poppins";
        margin-bottom: 25px;
        font-weight: 500;
        font-size: 21px;
    }

    .btn {
        background: #ccc;
        border: none;
    }
    .btn:hover, .btn.active {
        background: ${COLORS.primary};
        border: none;
    }

    .big-title {
        margin-top: 40px;
        font-size: 31px;
        font-family: "Poppins";
        font-weight: 500;
        text-align: center;
    }

    .title-sub {
        font-family: "Poppins";
        font-weight: 500;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .min-max {
        width: 50%;
        margin: auto;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    .list {
        display: flex;
        margin-top: 30px;
        margin-bottom: 70px;
        justify-content: space-between;
        @media screen and (max-width: 600px) {
            flex-wrap: wrap;
        }
        .goal {
            flex: 1;
            padding: 24px;
            background: ${COLORS.grey1};
            border-radius: 10px;
            text-align: center;
            margin: 0 10px;
            .title {
                font-family: "Poppins";
                font-weight: 500;
                font-size: 23px;
                color: ${COLORS.primary};
                margin-bottom: 20px;
            }
            @media screen and (max-width: 600px) {
                margin: 0 0 15px 0;
            }
            .subtitle {
                font-size: 22px;
            }
        }
        .goal:first-child {
            margin-left: 0;
        }
        .goal:last-child {
            margin-right: 0;
        }
        
    }

    .none {
        text-decoration: none;
        color:#fff;
    }

`