export const getEmojiFood = (category: string) => {
  switch(category) {
    case 'salades composées et crudités':
      return '🥗'
    case 'soupes':
      return '🍜'
    case 'plats composés':
      return '🥘'
    case 'Produits de la ruche':
    case 'Miels':
    case 'Miels-de-printemps':
      return '🍯'
    case 'pizzas, tartes et crêpes salées':
      return '🍕'
    case 'sandwichs':
      return '🥪'
    case 'feuilletées et autres entrées':
      return '🚧'
    case 'légumes':
    case 'Aliments et boissons à base de végétaux':
    case 'Aliments d\'origine végétale':
      return '🥬'
    case 'pommes de terre et autres tubercules':
      return '🥔'
    case 'légumineuses':
      return '🥜'
    case 'fruits':
      return '🍑'
    case 'fruits à coque et graines oléagineuses':
      return '🚧'
    case 'pâtes, riz et céréales':
      return '🍚'
    case 'pains et assimilés':
      return '🥖'
    case 'biscuits apéritifs':
      return '🥨'
    case 'viandes cuites':
      return '🍗'
    case 'viandes crues':
    case 'charcuteries et assimilés':
    case 'autres produits à base de viande':
    case 'substitus de produits carnés':
    case 'Pizzas tartes salées et quiches':
    case 'Pizzas':
    case 'Pizzas au thon':
      return '🥩'
    case 'poissons cuits':
    case 'produits à base de poissons et produits de la mer':
    case 'poissons crus':
      return '🐟'
    case 'mollusques et crustacés cuits':
    case 'mollusques et crustacés crus':
      return '🐚'
    case 'œufs':
      return '🍳'
    case 'laits':
    case 'produits laitiers frais et assimilés':
    case 'crèmes et spécialités à base de crème':
      return '🥛'
    case 'fromages et assimilés':
      return '🧀'
    case 'eaux':
      return '💧'
    case 'boissons sans alcool':
      return '🧃'
    case 'confiseries non chocolatées':
    case 'confitures et assimilés':
      return '🍭'
    case 'viennoiseries':
    case 'Petit-déjeuners':
      return '🥐'
    case 'biscuits sucrés':
      return '🍪'
    case 'céréales de petit-déjeuner':
      return '🥣'
    case 'barres céréalières':
      return '🌾'
    case 'gâteaux et pâtisseries':
      return '🍰'
    case 'glaces':
    case 'desserts glacés':
      return '🍦'
    case 'beurres':
    case 'autres matières grasses':
    case 'margarines':
      return '🧈'
    case 'huiles et graisses végétales':
    case 'huiles de poissons':
      return '🚧'
    case 'sauces':
      return '🥫'
    case 'condiments':
    case 'aides culinaires':
    case 'sels':
      return '🧂'
    case 'épices':
      return ''
    case 'Cafés':
    case 'Cafés en dosettes':
    case 'Cafés en dosettes compatible Nespresso':
      return '☕️'
    case 'herbes':
      return '🌿'
    case 'aides culinaires et ingrédients pour végétariens':
      return '🥦'
    case 'laits et boissons infantiles':
    case 'petits pots salés et plats infantiles':
    case 'desserts infantiles':
    case 'céréales et biscuits infantiles':
      return '🍼'
    case '-':
    case '':
      return '🚧'
    default:
      return '🚧'
  }
}