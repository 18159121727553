import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'

type StyledSidebarProps = {
  hiddenSidebar: boolean
}

export const StyledSidebar = styled['div']<StyledSidebarProps>`
  background: ${COLORS.white};
  min-width: 25vw;
  max-width: 25vw;
  max-height: calc(100vh - 72px);
  border-right: 1px solid ${COLORS.grey2};
  overflow-y: scroll;
  @media screen and (max-width: 600px) {
    ${({ hiddenSidebar }) => hiddenSidebar ? `
    display: none;` : `
    display: block;
    min-width: 100vw;
    max-width: 100vw;
    max-height: calc(100vh);
    `}
  }

  .title {
    font-family: "Poppins";
    font-weight: 600;
    span {
      font-weight: 400;
      color: #595959;
      padding-left: 5px;
    }
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    height: 70px;
    background: ${COLORS.grey1};
  }

  .plus{
    background: ${COLORS.primary};
    border-radius: 10px;
    color: #fff;
    height: 47px;
    width: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    cursor: pointer;
    padding-bottom: 6px;
  }

  .back {
    display:none;
    @media screen and (max-width: 600px) {
      display: flex;
      color: ${COLORS.primary};
      align-items: center;
      cursor: pointer;
    }
  }

  .form {
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    .input-group {
      width:46%;
      margin-top:18px;
    }
    .input-group-full {
      width:100%;
      margin-top:18px;
    }
    .required {
      color: ${COLORS.DELETE}
    }
  }

  .input-search {
    flex: 1;
    margin-right: 12px;
    form {
      display: flex;
      margin-bottom: 20px;
      input {
        flex: 1;
        background-color: #FFF;
        border: 1px solid #E8E8EF;
        box-sizing: border-box;
        box-shadow: 0px 2px 8px rgb(34 34 87 / 5%);
        border-radius: 5px;
        font-size: 16px;
        padding: 10px;
      }
      .ais-SearchBox-submit, .ais-SearchBox-reset {
        display:none;
      }
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }

  //REFACTO
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab-btn {
    color: ${COLORS['primary']};
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 400;
  }
  
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-ink-bar {
    background: ${COLORS['primary']};
  }

  .back {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 16px;
  }
`