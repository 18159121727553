import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'

type IStyledMeal = {
  isOverCurrent: boolean
}

export const StyledMeal = styled['div']<IStyledMeal>`
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgb(34 34 87 / 5%);
  border-radius: 10px;
  padding: 18px;
  transition: .2s ease-in-out;
  ${({ isOverCurrent }) => isOverCurrent ? 'transform: scale(1.01);' : ''}
  margin: 20px 20px 20px;
  @media screen and (max-width: 600px) {
    background: transparent;
    padding:0;
    box-shadow: none;
  }
  .border {
    width: 100%;
    height: 1px;
    margin-top: 18px;
    background: ${COLORS.grey2};
  }

  .create-food {
    display: none;
    @media screen and (max-width: 600px) {

      display: flex;
      padding: 10px;
      align-items: center;
      background: ${COLORS.primary};
      border-radius: 7px;
      margin: 10px 0;
      .round {
        height: 21px;
        width: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background: ${COLORS.white};
        margin-right: 8px;
      }
    }
    }

  .header {
    @media screen and (max-width: 600px) {
      position: relative;
      .copy, .paste.active {
        position: absolute;
        right: 54px;
        top: -8px;
      }
      .delete {
        position: absolute;
        right: 0;
        top: -8px;
      }
    }
  }

  .copy {
    background: ${COLORS.PROTEINS};
    border-radius: 6px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
  }

  .paste.active {
    background: ${COLORS.LIPIDS};
    border-radius: 6px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
  }

  .delete {
    background: ${COLORS.DELETE};
    border-radius: 6px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product_name {
    width:90%;
  }

  .overall {
    display: flex;
    align-items: center;    
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
    }
  }

  .outline-input {
    border: none;
    outline: none;
    font-family: "Poppins";
    font-size: 21px;
  }

`
