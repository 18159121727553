import styled from 'styled-components'


export const StyledScore = styled['div']`
.upper {
  margin-top: 8px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
}

.number {
  color: #6d6d6d;
}

&.group-overall {
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.balise {
  margin: 0 12px;
  @media screen and (max-width: 600px) {
    display: flex;
    margin: 20px 6px 0px;
    align-items: center;
    &:first-child {
          margin: 20px 6px 0px 0;
    }
    &:last-child {
          margin: 20px 0 0px;
    }
    .upper {
      margin-top:0;
      font-size: 14px;
      margin-left:4px;
      font-weight: 500;
    }
    .number {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.p {
  color: #3498DB;
}
.c {
  color: #E74C3C;
}
.l {
  color: #1ABC9C;
}
.g {
  color: #9B59B6;
}
`
