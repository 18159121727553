import { FC } from 'react'
import { Colors } from 'shared/style/constants'

import { StyledButton } from './core/Button.styles'
import { IProps } from './core/Button.types'

export const Button: FC<IProps> = ({
  children,
  className = '',
  onClick,
  mainColor = Colors.PRIMARY,
  secondaryColor = Colors.WHITE,
  hover = true,
  active = true
}) => (
  <StyledButton
    type="submit"
    className={className}
    onClick={onClick}
    mainColor={mainColor}
    secondaryColor={secondaryColor}
  >
    {children}
  </StyledButton>
)
