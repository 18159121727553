import { FC, useEffect, useState } from 'react'
import { StyledProgressGoal } from './core/progressGoal.styles'
import { IProps } from './core/progressGoal.types'
import { Plus } from 'react-feather'
import { Text } from 'components/Text'
import { FontSizes } from 'shared/style/constants'
import dayjs from 'dayjs'
import { Tag } from 'components/Tag'

export const ProgressGoal: FC<IProps> = props => {
  const { goal, days } = props
  const filters = {
    '1J': 1,
    '3J': 3,
    '7J': 7,
    '14J': 14,
    '1M': 30
  }

  const [filter, setFilter] = useState(Object.values(filters)[0])
  const [date, setDate] = useState('Du ... au ...')
  const [progressData, setProgressData] = useState({ calories: 0, fat: 0, carbs: 0, proteins: 0 })
  const [filteredDays, setFilteredDays] = useState({ calories: 0, fat: 0, carbs: 0, proteins: 0 })
  const [goalMax, setGoalMax] = useState({ calories: 0, fat: 0, carbs: 0, proteins: 0 })

  useEffect(() => {
    const day = dayjs().format('D MMM')
    const lastDay = dayjs()
      .subtract(filter === 1 ? 0 : filter, filter === 30 ? 'month' : 'day')
      .format('D MMM')
    setDate(`Du ${lastDay} au ${day}`)

    const dates: string[] = []

    for (let i = 0; i <= filter; i++) {
      const completeDate = dayjs().subtract(i, 'day').format('DD/MM/YYYY')
      dates.push(completeDate)
    }

    const filteredDays = Object.values(days)
      .filter(day => dates.includes(day.key))
      .reduce(
        (acc, day) => {
          acc = {
            calories: acc.calories + day.overall.calories,
            fat: acc.fat + day.overall.fat,
            carbs: acc.carbs + day.overall.carbs,
            proteins: acc.proteins + day.overall.proteins
          }
          return acc
        },
        { calories: 0, fat: 0, carbs: 0, proteins: 0 }
      )
    const goalValue = {
      calories: goal.calories * filter,
      carbs: goal.carbs * filter,
      fat: goal.fat * filter,
      proteins: goal.proteins * filter
    }

    setFilteredDays(filteredDays)
    setGoalMax(goalValue)

    const getPercentage = (key: 'calories' | 'carbs' | 'fat' | 'proteins') =>
      (filteredDays[key] / goalValue[key]) * 100 > 100 ? 100 : +((filteredDays[key] / goalValue[key]) * 100).toFixed(0)

    setProgressData({
      calories: isNaN(getPercentage('calories')) ? 0 : getPercentage('calories'),
      fat: isNaN(getPercentage('fat')) ? 0 : getPercentage('fat'),
      carbs: isNaN(getPercentage('carbs')) ? 0 : getPercentage('carbs'),
      proteins: isNaN(getPercentage('proteins')) ? 0 : getPercentage('proteins')
    })
  }, [days, filter])

  const getComplementInfo = (key: 'proteins' | 'carbs' | 'fat' | 'calories') => (
    <div className="line-row mt-4">
      <div>
        {filteredDays[key]}/{goalMax[key]}
        {key === 'calories' ? 'kcal' : 'g'}
      </div>
      <div>
        {goalMax[key] - filteredDays[key] <= 0 ? (
          <span>
            Objectif atteint: {goalMax[key] - filteredDays[key]} {key === 'calories' ? 'kcal' : 'g'}{' '}
          </span>
        ) : (
          <span>
            Restant: {goalMax[key] - filteredDays[key]} {key === 'calories' ? 'kcal' : 'g'}
          </span>
        )}
      </div>
    </div>
  )

  return (
    <StyledProgressGoal onClick={() => undefined}>
      <div className="header">
        <div className="day-name">{date}</div>
        <div className="filters">
          {Object.keys(filters).map(filterKey => (
            <div
              //@ts-ignore
              onClick={() => setFilter(filters[filterKey])}
              key={filterKey}
              //@ts-ignore
              className={`filter ${filter === filters[filterKey] ? 'active' : ''}`}
            >
              {filterKey}
            </div>
          ))}
        </div>
      </div>
      <div className="list">
        <div className="box">
          <div className="line-row">
            <div className="f calories">
              <div className="round c">C</div>
              <div className="progress bg">
                <div style={{ width: progressData.calories + '%' }} className="complete-line"></div>
              </div>
            </div>
            <div className="percentage">{progressData.calories}%</div>
          </div>
          {getComplementInfo('calories')}
        </div>
        <div className="box">
          <div className="line-row">
            <div className="f proteins">
              <div className="round p">P</div>
              <div className="progress bg">
                <div style={{ width: progressData.proteins + '%' }} className="complete-line"></div>
              </div>
            </div>
            <div className="percentage">{progressData.proteins}%</div>
          </div>
          {getComplementInfo('proteins')}
        </div>
        <div className="box">
          <div className="line-row">
            <div className="f carbs">
              <div className="round g">G</div>
              <div className="progress bg">
                <div style={{ width: progressData.carbs + '%' }} className="complete-line"></div>
              </div>
            </div>
            <div className="percentage">{progressData.carbs}%</div>
          </div>
          {getComplementInfo('carbs')}
        </div>
        <div className="box">
          <div className="line-row">
            <div className="f fat">
              <div className="round l">L</div>
              <div className="progress bg">
                <div style={{ width: progressData.fat + '%' }} className="complete-line"></div>
              </div>
            </div>
            <div className="percentage">{progressData.fat}%</div>
          </div>
          {getComplementInfo('fat')}
        </div>
      </div>
    </StyledProgressGoal>
  )
}
