import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'


export const StyledIndex = styled['div']`
    .container {
        width: 1200px;
        margin: auto;
        @media screen and (max-width: 600px) {
            width: 100%;
        }

        .ant-col {
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
    }

    .graph {
        padding: 24px 24px 34px;
        background: ${COLORS.grey1};
        margin-top: 20px;
        border-radius: 10px;
    }


    .params { 
        border-radius: 10px;
        cursor: pointer;
        margin-right: 12px;
        background: ${COLORS.primary};
    }

    .line {
        position: absolute;
        left:-5px;
        right: -5px;
        z-index: 111;
        height: 2px;
        background: #000;
        @media screen and (max-width: 600px) {z-index:0;}
    }

    .header-graph {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            text-transform:uppercase;
        }
        .info {
            text-align:right;
        }
    }

    .graph-line {
        display: flex;
        height: 140px;
        margin-top: 20px;
        position: relative;
        justify-content: space-between;
    }
`