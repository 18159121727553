import { Header, Text } from 'components'
import { DateLinePublic } from 'components/DateLinePublic'
import { FC, useState, useEffect } from 'react'
import { StyledIndex } from './core/index.styles'
import { selectDays, getProgram, selectAuthor, selectProgram } from './core/programPublicSlice'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import { FontSizes } from 'shared/style/constants'
import { useNavigate, useParams } from 'react-router-dom'
import { MealPublic } from 'components/MealPublic'
import { useDate } from 'components/DateLinePublic/core/dateLine.hooks'
import { loadUser, userSelector } from 'shared/user/userSlice'

const ProgramPublic: FC = () => {
  const dispatch = useAppDispatch()
  const days = useAppSelector(selectDays)
  const program = useAppSelector(selectProgram)
  const author = useAppSelector(selectAuthor)
  const user = useAppSelector(userSelector)
  const { id } = useParams()
  const navigate = useNavigate()
  const { activeWeek, weekNumber, setWeekNumber, activeMonthYear } = useDate()
  useEffect(() => {
    if (id && activeWeek) dispatch(getProgram({ id, weekComplete: activeWeek.map(week => week.completeDate) }))
  }, [id, activeWeek])

  useEffect(() => {
    dispatch(loadUser())
  }, [])

  useEffect(() => {
    if (author && program.private && program.authorId && author._id !== program.authorId) navigate('/')
  }, [program.private, program.authorId])

  return (
    <StyledIndex>
      {user.user && <Header />}
      <div className="header-program">
        <div className="box program">
          <div className="f f-jc-space-b">
            <div className="f f-jc-space-b f-ai-center">
              <Text className="f-jc-space-b" size={FontSizes.MEDIUM}>
                Programme {author && author.username}
              </Text>
            </div>
          </div>
          <DateLinePublic
            activeWeek={activeWeek}
            weekNumber={weekNumber}
            setWeekNumber={setWeekNumber}
            activeMonthYear={activeMonthYear}
          />
        </div>
      </div>
      <div className="days box">
        {days &&
          Object.values(days).length > 0 &&
          activeWeek.map((week, i) => (
            <div key={i} className="day">
              <div className="title">{week.dayStringComplete}</div>
              {days[week.completeDate] &&
                //@ts-ignore
                Object.values(days[week.completeDate].meals).map(meal => <MealPublic key={meal._id} meal={meal} />)}
            </div>
          ))}
      </div>
    </StyledIndex>
  )
}

export default ProgramPublic
