import React from 'react'
import { IStandardProps } from 'shared/types/common'

export type IProps = IStandardProps & {
    emoji: string 
    title: React.ReactNode 
    description: string 
    stats: {
        calories: string
        glucides: string
        proteines: string
        lipides: string
    }
    data: any,
}

export const ItemTypes = {
  FOOD: 'food'
}