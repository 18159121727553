import { FC, useCallback, useEffect, useState } from 'react'

import { IProps } from './core/modalUpdateGoal.types'
import { Modal, InputNumber, Collapse } from 'antd'
import { StyledModalUpdateProgram, StyledModal } from './core/modalUpdateGoal.styles'
import { Colors } from 'shared/style/constants'
import { Button } from 'components'
import { Alert } from 'antd'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'shared/helpers/utils'
import usePrevious from './core/modalUpdateGoal.hooks'

const { Panel } = Collapse

export const ModalUpdateGoal: FC<IProps> = props => {
  const { modalIsVisible, goal, handleChangeVisible, onOk } = props
  const [calories, setCalories] = useState(goal.calories)
  const [weight, setWeight] = useState(goal.weight?.value !== 0 && goal.weight ? goal.weight?.value : '-')
  const [proteins, setProteins] = useState(goal.proteins)
  const [fat, setFat] = useState(goal.fat)
  const [carbs, setCarbs] = useState(goal.carbs)
  const [isCalActive, setIsCalActive] = useState(true)
  const prevCalories = usePrevious(calories)
  const prevProteins = usePrevious(proteins)
  const prevFat = usePrevious(fat)
  const prevCarbs = usePrevious(carbs)
  const navigate = useNavigate()
  useEffect(() => {
    setCalories(goal.calories)
    setProteins(goal.proteins)
    setWeight(goal.weight?.value !== 0 && goal.weight ? goal.weight?.value : '-')
    setFat(goal.fat)
    setCarbs(goal.carbs)
  }, [goal])

  useEffect(() => {
    if (prevProteins === undefined && prevFat === undefined && prevCarbs === undefined) return
    if (!isCalActive) {
      //@ts-ignore
      const diffProt = (proteins - prevProteins) * 4
      //@ts-ignore
      const diffCarbs = (carbs - prevCarbs) * 4
      //@ts-ignore
      const diffFat = (fat - prevFat) * 9
      setCalories(calories + diffProt + diffCarbs + diffFat)
    }
  }, [proteins, fat, carbs])

  useEffect(() => {
    if (prevProteins && prevFat && prevCarbs && typeof weight !== 'string' && weight > 0 && isCalActive) {
      const protDay = Math.round(weight * 1.8)
      const fatDay = Math.round(weight * 1.2)
      const carbsDay = Math.round(calories - protDay * 4 + fatDay * 9)
      setProteins(protDay)
      setCarbs(carbsDay)
      setFat(fatDay)
    }
  }, [calories, weight])

  return (
    <StyledModalUpdateProgram>
      <StyledModal />
      <Modal
        footer={
          <div className="footer">
            <Button
              onClick={() => handleChangeVisible(false)}
              mainColor={Colors.WHITE}
              secondaryColor={Colors.PRIMARY}
              hover={true}
              active={false}
            >
              Annuler
            </Button>
            <Button
              onClick={() => onOk({ calories, fat, carbs, proteins, weight: typeof weight === 'string' ? 0 : weight })}
              mainColor={Colors.PRIMARY}
              secondaryColor={Colors.WHITE}
              hover={true}
              active={false}
            >
              Enregistrer
            </Button>
          </div>
        }
        width={600}
        className="modal-goal"
        visible={modalIsVisible}
        onCancel={() => handleChangeVisible(false)}
      >
        <div className="title">Définir mes objectifs</div>
        <Button
          onClick={() => navigate('/daily-calories')}
          mainColor={Colors.PRIMARY}
          secondaryColor={Colors.WHITE}
          hover={true}
          active={false}
        >
          Grâce au formulaire
        </Button>
        <div className="or">ou</div>
        <div className="form-group">
          <div className="input-group">
            <div className="label">Poids</div>
            <InputNumber addonAfter="kg" name="weight" value={weight} onChange={(value: any) => setWeight(value)} />
          </div>
          <div className="input-group">
            <div className="label">Calories</div>
            <InputNumber
              min={0}
              addonAfter="kcal"
              name="calories"
              onBlur={() => setIsCalActive(true)}
              value={calories}
              onChange={(value: any) => setCalories(value)}
            />
          </div>
          <div className="input-group">
            <div className="label">Protéines</div>
            <InputNumber
              min={0}
              addonAfter="g"
              name="proteins"
              onBlur={() => setIsCalActive(false)}
              value={proteins}
              onChange={(value: any) => setProteins(value)}
            />
          </div>

          <div className="input-group">
            <div className="label">Glucides</div>
            <InputNumber
              min={0}
              addonAfter="g"
              name="carbs"
              onBlur={() => setIsCalActive(false)}
              value={carbs}
              onChange={(value: any) => setCarbs(value)}
            />
          </div>
          <div className="input-group">
            <div className="label">Lipides</div>
            <InputNumber
              min={0}
              addonAfter="g"
              name="fat"
              onBlur={() => setIsCalActive(false)}
              value={fat}
              onChange={(value: any) => setFat(value)}
            />
          </div>
        </div>
        <Alert
          message="Rappel"
          description={
            <div>
              <strong>
                1g de protéine = 4kcal <br /> 1g de glucide = 4kcal <br /> 1g de lipide = 9kcal{' '}
              </strong>
            </div>
          }
          type="info"
          showIcon
        />{' '}
      </Modal>
    </StyledModalUpdateProgram>
  )
}
