import { FC, useEffect, useState } from 'react'
import { StyledIndex } from './core/index.styles'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import MainLayout from '../../layouts/MainLayout'
import { Row, Col, Input, Switch, Slider } from 'antd'
import { Button, Text } from 'components'
import { Colors, FontFamilies, FontSizes, FontWeights } from 'shared/style/constants'
import { userSelector } from 'shared/user/userSlice'
import { updateGoal } from 'pages/Index/core/goalSlice'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

const DailyCalories: FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(userSelector)
  const [gender, setGender] = useState('')
  const [old, setOld] = useState(0)
  const [height, setHeight] = useState(0)
  const [weight, setWeight] = useState(0)
  const [activity, setActivity] = useState('')
  const [purposeGoal, setPurposeGoal] = useState({ min: 0, max: 0 })
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const TEFmin = 1.05
  const TEFmax = 1.15

  const getNap = (gender: string, activity: string) => {
    if (activity === '0') {
      return 1
    }
    if (activity === '1') {
      return gender === 'H' ? 1.11 : 1.12
    }
    if (activity === '2') {
      return gender === 'H' ? 1.25 : 1.27
    }
    if (activity === '3') {
      return gender === 'H' ? 1.48 : 1.45
    }
    return 1
  }

  const calculateTBM = () => {
    if (old && height && weight && gender !== '' && activity !== '') {
      const RTEmin = 0.1 * weight * 15
      const RTEmax = 0.1 * weight * 30
      const nap = getNap(gender, activity)
      let tmb = 0
      if (gender === 'H') {
        tmb = 13.707 * weight + 492.3 * (height / 100) - 6.673 * old + 77.0607
      } else {
        tmb = 9.74 * weight + 172.9 * (height / 100) - 4.737 * old + 667.051
      }
      const DEJmin = +((tmb * nap + RTEmin) * TEFmin).toFixed(0)
      const DEJmax = +((tmb * nap + RTEmax) * TEFmax).toFixed(0)
      setPurposeGoal({ max: DEJmax, min: DEJmin })
    } else {
      setError(true)
    }
  }

  return (
    <MainLayout hideSidebar>
      <StyledIndex>
        <div className="container">
          <Row gutter={16}>
            <Col className="gutter-row" sm={24} md={24}>
              <div className="f-wrap f f-jc-space-b f-ai-center">
                <Text size={FontSizes.LARGE} font={FontFamilies.POPPINS} weight={FontWeights.BOLD}>
                  Calcul de son besoin caloriques journalier
                </Text>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" sm={24} md={12}>
              <div className="box">
                <div className="question">Quel est votre sexe ?</div>
                <div className="f f-jc-space-b f-ai-center group-btn">
                  <Button
                    className={`btn ${gender === 'H' ? 'active' : ''}`}
                    mainColor={Colors.PROTEINS}
                    secondaryColor={Colors.WHITE}
                    hover={true}
                    active={false}
                    onClick={() => setGender('H')}
                  >
                    Homme
                  </Button>
                  <Button
                    className={`btn ${gender === 'F' ? 'active' : ''}`}
                    mainColor={Colors.PROTEINS}
                    secondaryColor={Colors.WHITE}
                    hover={true}
                    active={false}
                    onClick={() => setGender('F')}
                  >
                    Femme
                  </Button>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" sm={24} md={12}>
              <div className="box">
                <div className="question">Quel est votre âge ?</div>
                <Input placeholder="18" onChange={e => setOld(+e.target.value)} addonAfter="ans" />
              </div>
            </Col>
            <Col className="gutter-row" sm={24} md={12}>
              <div className="box">
                <div className="question">Combien mesurez-vous ?</div>
                <Input placeholder="180" onChange={e => setHeight(+e.target.value)} addonAfter="cm" />
              </div>
            </Col>
            <Col className="gutter-row" sm={24} md={12}>
              <div className="box">
                <div className="question">Combien vous pesez ?</div>
                <Input placeholder="80" onChange={e => setWeight(+e.target.value)} addonAfter="kg" />
              </div>
            </Col>
            <Col className="gutter-row" sm={24} md={24}>
              <div className="box">
                <div className="question">Quel est votre niveau d&apos;activité ?</div>
                <div className="list-activity">
                  <div>
                    <Button
                      className={`btn ${activity === '0' ? 'active' : ''}`}
                      mainColor={Colors.PROTEINS}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => setActivity('0')}
                    >
                      Sédentaire
                    </Button>
                    <p>Pas d’activité physique</p>
                  </div>
                  <div>
                    <Button
                      className={`btn ${activity === '1' ? 'active' : ''}`}
                      mainColor={Colors.PROTEINS}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => setActivity('1')}
                    >
                      Moyennement actif
                    </Button>
                    <p>1 à 3 fois par semaine</p>
                  </div>
                  <div>
                    <Button
                      className={`btn ${activity === '2' ? 'active' : ''}`}
                      mainColor={Colors.PROTEINS}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => setActivity('2')}
                    >
                      Actif
                    </Button>
                    <p>Exercices d’intensité modérée 3 à 5 fois par semaine, marche 2 à 5 km par jour</p>
                  </div>
                  <div>
                    <Button
                      className={`btn ${activity === '3' ? 'active' : ''}`}
                      mainColor={Colors.PROTEINS}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => setActivity('3')}
                    >
                      Très actif
                    </Button>
                    <p>Exercices de forte intensité 6 fois par semaine, marche plus de 5 km par jour</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="f f-jc-space-b f-ai-center mt-5">
              <Button
                mainColor={Colors.PRIMARY}
                secondaryColor={Colors.WHITE}
                hover={true}
                active={false}
                onClick={() => calculateTBM()}
              >
                <a className="none" href="#goal">
                  Calculer
                </a>
              </Button>
            </Col>
          </Row>
          {purposeGoal.max > 0 && (
            <>
              <div id="goal" className="big-title">
                Les objectifs proposés{' '}
              </div>
              <div className="list">
                <div className="goal">
                  <div className="title">Pour perdre du poids</div>
                  <div className="subtitle">
                    <b>{purposeGoal.min - 280}</b>kcal à <b>{purposeGoal.max - 380}</b>kcal
                  </div>
                  <div className="title-sub">Définir comme objectif</div>
                  <div className="f f-jc-space-b f-ai-center min-max">
                    <Button
                      mainColor={Colors.PRIMARY}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => {
                        dispatch(
                          updateGoal({
                            goal: {
                              _id: v4(),
                              calories: Math.round(purposeGoal.min - 280),
                              proteins: Math.round(weight * 1.8),
                              fat: Math.round(weight * 1.2),
                              carbs: Math.round((purposeGoal.min - 280 - (weight * 1.8 * 4 + weight * 1.2 * 9)) / 4),
                              weight: {
                                value: weight,
                                target: 0
                              }
                            }
                          })
                        )
                        navigate('/')
                      }}
                    >
                      Min
                    </Button>
                    <Button
                      mainColor={Colors.PRIMARY}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => {
                        dispatch(
                          updateGoal({
                            goal: {
                              _id: v4(),
                              calories: Math.round(purposeGoal.max - 380),
                              proteins: Math.round(weight * 1.8),
                              fat: Math.round(weight * 1.2),
                              carbs: Math.round((purposeGoal.max - 380 - (weight * 1.8 * 4 + weight * 1.2 * 9)) / 4),
                              weight: {
                                value: weight,
                                target: 0
                              }
                            }
                          })
                        )
                        navigate('/')
                      }}
                    >
                      Max
                    </Button>
                  </div>
                </div>
                <div className="goal">
                  <div className="title">Pour stabiliser son poids</div>
                  <div className="subtitle">
                    <b>{purposeGoal.min}</b>kcal à <b>{purposeGoal.max}</b>kcal
                  </div>
                  <div className="title-sub">Définir comme objectif</div>
                  <div className="f f-jc-space-b f-ai-center min-max">
                    <Button
                      mainColor={Colors.PRIMARY}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => {
                        dispatch(
                          updateGoal({
                            goal: {
                              _id: v4(),
                              calories: Math.round(purposeGoal.min),
                              proteins: Math.round(weight * 1.8),
                              fat: Math.round(weight * 1.2),
                              carbs: Math.round((purposeGoal.min - (weight * 1.8 * 4 + weight * 1.2 * 9)) / 4),
                              weight: {
                                value: weight,
                                target: 0
                              }
                            }
                          })
                        )
                        navigate('/')
                      }}
                    >
                      Min
                    </Button>
                    <Button
                      mainColor={Colors.PRIMARY}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => {
                        dispatch(
                          updateGoal({
                            goal: {
                              _id: v4(),
                              calories: Math.round(purposeGoal.max),
                              proteins: Math.round(weight * 1.8),
                              fat: Math.round(weight * 1.2),
                              carbs: Math.round((purposeGoal.max - (weight * 1.8 * 4 + weight * 1.2 * 9)) / 4),
                              weight: {
                                value: weight,
                                target: 0
                              }
                            }
                          })
                        )
                        navigate('/')
                      }}
                    >
                      Max
                    </Button>
                  </div>
                </div>
                <div className="goal">
                  <div className="title">Pour prendre du poids</div>
                  <div className="subtitle">
                    <b>{purposeGoal.min + 380}</b>kcal à <b>{purposeGoal.max + 380}</b>kcal
                  </div>
                  <div className="title-sub">Définir comme objectif</div>
                  <div className="f f-jc-space-b f-ai-center min-max">
                    <Button
                      mainColor={Colors.PRIMARY}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => {
                        dispatch(
                          updateGoal({
                            goal: {
                              _id: v4(),
                              calories: Math.round(purposeGoal.min + 380),
                              proteins: Math.round(weight * 1.8),
                              fat: Math.round(weight * 1.2),
                              carbs: Math.round((purposeGoal.min + 380 - (weight * 1.8 * 4 + weight * 1.2 * 9)) / 4),
                              weight: {
                                value: weight,
                                target: 0
                              }
                            }
                          })
                        )
                        navigate('/')
                      }}
                    >
                      Min
                    </Button>
                    <Button
                      mainColor={Colors.PRIMARY}
                      secondaryColor={Colors.WHITE}
                      hover={true}
                      active={false}
                      onClick={() => {
                        dispatch(
                          updateGoal({
                            goal: {
                              _id: v4(),
                              calories: Math.round(purposeGoal.max + 380),
                              proteins: Math.round(weight * 1.8),
                              fat: Math.round(weight * 1.2),
                              carbs: Math.round((purposeGoal.max + 380 - (weight * 1.8 * 4 + weight * 1.2 * 9)) / 4),
                              weight: {
                                value: weight,
                                target: 0
                              }
                            }
                          })
                        )
                        navigate('/')
                      }}
                    >
                      Max
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </StyledIndex>
    </MainLayout>
  )
}

export default DailyCalories
