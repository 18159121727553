import { Button, DateLine, Meal, Text, TextInput } from 'components'
import { FC, useState, useEffect } from 'react'
import { StyledLogin } from './core/register.styles'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import { Map } from 'react-feather'
import { COLORS, Colors, FontFamilies, FontWeights } from 'shared/style/constants'
import { Link, useNavigate } from 'react-router-dom'
import { signUp, userSelector } from 'shared/user/userSlice'
import Logo from 'shared/assets/logo.svg'

const Register: FC = () => {
  const dispatch = useAppDispatch()
  const [fullname, setFullname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showError, setShowError] = useState(false)
  const { isLoading, isSuccess, isError, errorMessage } = useAppSelector(userSelector)
  const navigate = useNavigate()

  function handleSubmit(e: any) {
    e.preventDefault()
    dispatch(signUp({ email, password, username: fullname }))
  }

  useEffect(() => {
    if (isSuccess && email.length > 3 && password.length > 3) navigate('/daily-calories')
    if (isError) setShowError(true)
  }, [isSuccess, isError])

  return (
    <StyledLogin>
      <div className="left-side">
        <div className="box">
          <div className="logo f f-center">
            <img src={Logo} className="mr-1" />
            <div className="text">NutriPlan</div>
          </div>

          {isError && <div className="error mt-4">{errorMessage}</div>}
          <form onSubmit={e => handleSubmit(e)}>
            <TextInput
              placeholder="Prénom"
              className="mt-3"
              value={fullname}
              name="fullname"
              type="text"
              setValue={setFullname}
            />
            <TextInput
              placeholder="Email"
              className="mt-1"
              value={email}
              name="email"
              type="email"
              setValue={setEmail}
            />
            <TextInput
              className="mt-1"
              value={password}
              name="password"
              type="password"
              placeholder="Mot de passe"
              setValue={setPassword}
            />
            <div className="mt-4 f f-jc-space-b">
              <Button
                onClick={e => handleSubmit(e)}
                className="w-100"
                mainColor={Colors.PRIMARY}
                secondaryColor={Colors.WHITE}
              >
                <div>Je m&apos;inscris</div>
              </Button>
            </div>
          </form>
          <div className="not-register">
            Déjà inscrit ?{' '}
            <Link className="link" to="/login">
              Se connecter
            </Link>
          </div>
        </div>
      </div>
      <div className="right-side"></div>
    </StyledLogin>
  )
}

export default Register
