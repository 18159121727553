import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'
import { createGlobalStyle } from 'styled-components'


export const StyledModalUpdateProgram = styled['div']`
    .modal-goal .ant-modal-body {
        padding: 30px;
    }
`


export const StyledModal = createGlobalStyle`
    .modal-goal .ant-modal-body {
        padding: 30px 30px 20px;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 12px 0;
    }

    .modal-goal {
        .input-group {
            width: 47%;
            margin-bottom: 10px;
        }

        .form-group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }

        .label {
            font-size: 18px;
        }

        .title {
            font-family: "Poppins";
            text-align:center;
            font-weight: 700;
            font-size: 21px;
            margin-bottom: 15px;
        }

        .collapse {
            margin-bottom: 15px;
        }
        
        .or {
            font-weight: 500;
            margin: 20px 0;
            font-size: 19px;
        }
    }
`
