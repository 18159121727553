import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'


export const StyledDateLinePublic = styled['div']`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 21px;
  .days {
    user-select: none;
    display: flex;
    justify-content: space-between;
    flex: 0.96;
  }
  .year-date {
    user-select: none;
    text-align: center;
    .month {
      font-size: 30px;
      text-transform: uppercase;
    }
    .year {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .round {
    width: 68px;
    height: 68px;
    position: relative;
    background: ${COLORS.grey2};
    border-radius: 50%;
    display: flex;
    cursor:pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .number {
      font-size: 26px;
      font-weight: 700;
    }
  }
`

type IStyledDate = {
  kcal: string
}

export const StyledDate = styled.div<IStyledDate>`
  & {
    width: 68px;
    height: 68px;
    position: relative;
    background: ${COLORS.grey2};
    border-radius: 50%;
    display: flex;
    cursor:pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .number {
      font-size: 26px;
      font-weight: 700;
    }
  }
  &.active {
    background: ${COLORS.primary};
    color: #fff;
    position: relative;
  }
  &::after {
   ${({ kcal }) => kcal ? `content: "${kcal} kcal"` : 'content: "abc"'};
    position: absolute;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #717171;
    bottom: -20px;
  }
`
