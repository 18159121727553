import { FC } from 'react'
import { StyledMainLayout } from './typescript/mainLayout.styles'
import { Header, Sidebar } from 'components'
import { IProps } from './typescript/mainLayout.types'

const MainLayout: FC<IProps> = props => {
  const { hideSidebar, children } = props
  return (
    <StyledMainLayout {...props} className="f f-direction-column">
      <Header />
      {hideSidebar ? (
        <div className="content p-4">{children}</div>
      ) : (
        <div className="main f f-jc-space-b">
          <Sidebar className="sidebar" />
          <div className="content p-2">{children}</div>
        </div>
      )}
    </StyledMainLayout>
  )
}

export default MainLayout
