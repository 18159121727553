import { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import { createMeal, selectActiveDay } from 'pages/Program/core/programSlice'

import { StyledWeekBar } from './core/weekBar.styles'
import { IProps } from './core/weekBar.types'
import { Plus } from 'react-feather'
import { Text } from 'components/Text'
import { FontSizes } from 'shared/style/constants'
import { Tooltip } from 'antd'

export const WeekBar: FC<IProps> = props => {
  const { type, value = 0, max, day } = props

  return (
    <StyledWeekBar className={type} onClick={() => undefined}>
      <Tooltip placement="top" title={value + 'g'}>
        <div className="bg">
          <div style={{ height: ((value / max) * 100).toString() + '%' }} className="complete-line"></div>
        </div>
      </Tooltip>
      <div className="day-name">{day}</div>
    </StyledWeekBar>
  )
}
