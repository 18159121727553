import { Routes, Route } from 'react-router-dom'
import { GlobalStyle } from '../shared/style'
import { DndProvider } from 'react-dnd'
import Index from '../pages/Index'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ProtectedRoute } from 'shared/helpers/PrivateRoute'
import Program from 'pages/Program'
import Login from 'pages/Login'
import Register from 'pages/Register'
import ProgramPublic from 'pages/ProgramPublic'
import DailyCalories from 'pages/DailyCalories'

const Router = () => {
  return (
    <>
      <GlobalStyle />
      <DndProvider backend={HTML5Backend}>
        <Routes>
          <Route
            key={0}
            path={'/'}
            element={
              <ProtectedRoute>
                <Index />
              </ProtectedRoute>
            }
          />
          <Route
            key={1}
            path={'/program'}
            element={
              <ProtectedRoute>
                <Program />
              </ProtectedRoute>
            }
          />
          <Route
            key={5}
            path={'/daily-calories'}
            element={
              <ProtectedRoute>
                <DailyCalories />
              </ProtectedRoute>
            }
          />
          <Route key={4} path={'/program/:id'} element={<ProgramPublic />} />
          <Route key={2} path={'/login'} element={<Login />} />
          <Route key={3} path={'/register'} element={<Register />} />
        </Routes>
      </DndProvider>
    </>
  )
}

export default Router
