import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import client from 'shared/helpers/client'
import { RootState, AppThunk } from 'shared/store'
import { message } from 'antd'
import { loadUser } from 'shared/user/userSlice'

export type GoalSlice = { 
    _id: null | string
    calories: number
    proteins: number
    weight?: {
      value: number
      target: number
    },
    author?: string
    carbs: number
    fat: number
 }

const initialState: GoalSlice = {
  _id: null,
  calories: 0,
  author: undefined,
  proteins: 0,
  carbs: 0,
  fat: 0
}

export const updateGoal = createAsyncThunk(
  'goal/updateGoal',
  async ({goal}: { goal: GoalSlice }, thunkAPI) => {
    const response = await client.put('goal',
      goal
    )
    if (response.status < 400) {
      thunkAPI.dispatch(loadUser())
      return response.data
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const getGoal = createAsyncThunk(
  'goal/getGoal',
  async ({id}: { id: string }, thunkAPI) => {
    const response = await client.get(`goal/${id}`)
    if (response.status < 400) {
      return { goal: response.data }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

// Reducers
export const goalSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateGoal.fulfilled, (state, {payload}) => {
      message.success('Les objectifs ont été mis à jour!')
      return state = payload
    })
    builder.addCase(getGoal.fulfilled, (state, {payload}) => {
      return state = payload.goal
    })
  }
})

// Export actions
//export const {} = goalSlice.actions

// Selectors
export const selectGoal= (state: RootState) => state.goal


export default goalSlice.reducer
