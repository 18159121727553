import styled from 'styled-components'
import {
  BORDER_RADIUS,
  COLORS,
  SPACINGS,
} from 'shared/style/constants'
import { IProps } from './Button.types'

export const StyledButton = styled('button')<IProps>`
  padding: ${SPACINGS[2]} ${SPACINGS[4]};
    cursor: pointer;
    border-radius: ${BORDER_RADIUS[1]};
    transition: color 150ms, background-color 150ms, transform 100ms;
    font-family: 'poppins';
    font-weight: 500;
${({ mainColor, secondaryColor }) => `
 background-color: ${COLORS[mainColor]};
 border: 1px solid ${COLORS[mainColor]};
 color: ${COLORS[secondaryColor]};
 
 &:active {
   transform: scale(0.95);
  }
        `}
`