import { FC, useState } from 'react'

import { SearchRow, Text } from 'components'
import { COLORS, FontFamilies, FontWeights } from 'shared/style/constants'
import { StyledSidebar } from './core/sidebar.styles'
import { IProps } from './core/sidebar.types'
import { Input, InputNumber, Tabs } from 'antd'
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { normalizeStat } from 'shared/helpers/utils'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Button } from 'components/Button'
export type { IProps as ISidebarProps } from './core/sidebar.types'
import { Colors } from 'shared/style/constants'
import { v4 } from 'uuid'
import { changeSidebarHidden, createFood, selectHiddenSidebar } from 'pages/Program/core/programSlice'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import { getEmojiFood } from 'shared/helpers/getEmoji'

const { TabPane } = Tabs

export const Sidebar: FC<IProps> = props => {
  const { className } = props
  const dispatch = useAppDispatch()
  const hiddenSidebar = useAppSelector(selectHiddenSidebar)
  const [showFood, setShowFood] = useState(false)
  const [form, setForm] = useState({
    product_name: '',
    brands: '',
    serving_quantity: 100,
    'energy-kcal_100g': 0,
    proteins_100g: 0,
    carbohydrates_100g: 0,
    fiber_100g: 0,
    sugars_100g: 0,
    polyols_100g: 0,
    fat_100g: 0,
    'saturated-fat_100g': 0,
    'polyunsaturated-fat_100g': 0,
    'monounsaturated-fat_100g': 0,
    cholesterol_100g: 0,
    sodium_100g: 0,
    salt_100g: 0,
    'vitamin-d_100g': 0,
    'vitamin-a_100g': 0,
    'vitamin-c_100g': 0,
    calcium_100g: 0,
    iron_100g: 0,
    potassium_100g: 0,
    magnesium_100g: 0
  })
  const searchClient =
    process.env.REACT_APP_MEILISEARCH_URL &&
    instantMeiliSearch(process.env.REACT_APP_MEILISEARCH_URL, process.env.REACT_APP_MEILISEARCH_KEY)

  function getValues(key: string, value: string | number) {
    setForm({ ...form, [key]: value })
  }

  const Hit = ({ hit }: { hit: any }) => (
    <div>
      <SearchRow
        data={hit}
        emoji={getEmojiFood(hit.categories_fr)}
        title={
          <div className="f">
            <span className="title">
              {hit.product_name || 'Non défini'}
              <span>{hit.brands}</span>
            </span>
          </div>
        }
        description={
          hit.serving_quantity !== undefined && hit.serving_quantity !== '' ? hit.serving_quantity + 'g' : '100g'
        }
        stats={{
          calories: normalizeStat(hit['energy-kcal_100g'], 'kcal', hit.serving_quantity),
          proteines: normalizeStat(hit.proteins_100g, 'g', hit.serving_quantity),
          glucides: normalizeStat(hit.carbohydrates_100g, 'g', hit.serving_quantity),
          lipides: normalizeStat(hit.fat_100g, 'g', hit.serving_quantityg)
        }}
      />
    </div>
  )

  return (
    <StyledSidebar hiddenSidebar={hiddenSidebar} {...props} className={`${className || ''}`}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Aliments" key="1" className="p-2">
          {!showFood ? (
            <InstantSearch indexName="foods" searchClient={searchClient}>
              <div className="f f-jc-space-b">
                <SearchBox
                  translations={{
                    submitTitle: 'Chercher les résultats',
                    resetTitle: 'Réinitialiser la rechercher',
                    placeholder: 'Chercher un aliment'
                  }}
                  className="input-search"
                />
                <div onClick={() => setShowFood(true)} className="plus">
                  +
                </div>
              </div>
              <Hits hitComponent={Hit} />
            </InstantSearch>
          ) : (
            <div>
              <div onClick={() => setShowFood(false)} className="back">
                <ChevronLeft color={COLORS.primary} /> Retour
              </div>
              <div className="mt-3 title">Ajouter un aliment</div>
              <form className="form">
                <div className="input-group-full">
                  <div className="label">
                    Nom du produit <span className="required">*</span>
                  </div>
                  <Input
                    required
                    value={form['product_name']}
                    onChange={value => getValues('product_name', value.target.value)}
                    placeholder="ex: Fromage blanc"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Marque</div>
                  <Input
                    value={form['brands']}
                    onChange={value => getValues('brands', value.target.value)}
                    placeholder="ex: Carrefour"
                  />
                </div>
                <div className="input-group">
                  <div className="label">
                    Portion <span className="required">*</span>
                  </div>
                  <InputNumber
                    required
                    onChange={value => getValues('serving_quantity', value)}
                    min={0}
                    defaultValue={100}
                    value={form['serving_quantity']}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">
                    Calories <span className="required">*</span>
                  </div>
                  <InputNumber
                    required
                    onChange={value => getValues('energy-kcal_100g', value)}
                    min={0}
                    value={form['energy-kcal_100g']}
                    addonAfter="kcal"
                  />
                </div>
                <div className="input-group">
                  <div className="label">
                    Protéines <span className="required">*</span>
                  </div>
                  <InputNumber
                    value={form['proteins_100g']}
                    required
                    min={0}
                    onChange={value => getValues('proteins_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">
                    Glucides <span className="required">*</span>
                  </div>
                  <InputNumber
                    required
                    min={0}
                    value={form['carbohydrates_100g']}
                    onChange={value => getValues('carbohydrates_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Fibre alimentaire</div>
                  <InputNumber
                    value={form['fiber_100g']}
                    min={0}
                    onChange={value => getValues('fiber_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Sucres</div>
                  <InputNumber
                    value={form['sugars_100g']}
                    min={0}
                    onChange={value => getValues('sugars_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Polyols</div>
                  <InputNumber
                    value={form['polyols_100g']}
                    min={0}
                    onChange={value => getValues('polyols_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Lipides</div>
                  <InputNumber
                    value={form['fat_100g']}
                    min={0}
                    onChange={value => getValues('fat_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Acides gras saturés</div>
                  <InputNumber
                    min={0}
                    value={form['saturated-fat_100g']}
                    onChange={value => getValues('saturated-fat_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Acides gras polyinsaturés</div>
                  <InputNumber
                    min={0}
                    value={form['polyunsaturated-fat_100g']}
                    onChange={value => getValues('polyunsaturated-fat_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Acides gras monoinsaturés</div>
                  <InputNumber
                    min={0}
                    value={form['monounsaturated-fat_100g']}
                    onChange={value => getValues('monounsaturated-fat_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Cholestérol</div>
                  <InputNumber
                    value={form['cholesterol_100g']}
                    min={0}
                    onChange={value => getValues('cholesterol_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Sodium</div>
                  <InputNumber
                    value={form['sodium_100g']}
                    min={0}
                    onChange={value => getValues('sodium_100g', value)}
                    addonAfter="mg"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Sel</div>
                  <InputNumber
                    value={form['salt_100g']}
                    min={0}
                    onChange={value => getValues('salt_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Vitamine D</div>
                  <InputNumber
                    value={form['vitamin-d_100g']}
                    min={0}
                    onChange={value => getValues('vitamin-d_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Calcium</div>
                  <InputNumber
                    value={form['calcium_100g']}
                    min={0}
                    onChange={value => getValues('calcium_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Fer</div>
                  <InputNumber
                    value={form['iron_100g']}
                    min={0}
                    onChange={value => getValues('iron_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Potassium</div>
                  <InputNumber
                    value={form['potassium_100g']}
                    min={0}
                    onChange={value => getValues('potassium_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Vitamine A</div>
                  <InputNumber
                    value={form['vitamin-a_100g']}
                    min={0}
                    onChange={value => getValues('vitamin-a_100g', value)}
                    addonAfter="%"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Vitamine C</div>
                  <InputNumber
                    min={0}
                    value={form['vitamin-c_100g']}
                    onChange={value => getValues('vitamin-c_100g', value)}
                    addonAfter="%"
                  />
                </div>
                <div className="input-group">
                  <div className="label">Magnesium</div>
                  <InputNumber
                    min={0}
                    value={form.magnesium_100g}
                    onChange={value => getValues('magnesium_100g', value)}
                    addonAfter="g"
                  />
                </div>
                <div className="input-group-full">
                  <Button
                    onClick={() => {
                      setShowFood(false)
                      dispatch(createFood({ data: { id: v4(), ...form } }))
                    }}
                    mainColor={Colors.PRIMARY}
                    secondaryColor={Colors.WHITE}
                    hover={true}
                    active={false}
                  >
                    Ajouter l&apos;aliment
                  </Button>
                </div>
              </form>
            </div>
          )}
        </TabPane>
        <TabPane tab="Mes recettes" disabled key="2">
          Content of Tab Pane 2
        </TabPane>
      </Tabs>
    </StyledSidebar>
  )
}
