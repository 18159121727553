import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import client from 'shared/helpers/client'
import { transformArrayByObjectByKey } from 'shared/helpers/utils'
import { RootState, AppThunk } from 'shared/store'
import { loadUser, User } from 'shared/user/userSlice'
import { v4 as uuidv4 } from 'uuid'

export type ProgramPublicSlice = { author?: User, authorId?: string, activeDay: string, private: boolean, days: Record<string, Day> }

export type Day = {
  meals: MealState // For the moment
  key: string
  overall: Overall
}

export type MealState = Record<string, {
    name: string
    _id: string | null
    overall: Overall
    foods: {
      food: any
      quantity: number
      size: number
    }[]
}>

type Overall = {
  calories: number
  proteins: number
  fat: number
  carbs: number
}

const initialState: ProgramPublicSlice = {
  activeDay: '',
  days: {},
  private: true,
  author: undefined,
  authorId: undefined
}

export const getProgram = createAsyncThunk(
  'programPublic/getProgram',
  async ({id, weekComplete}: { id: string, weekComplete: string[] }, thunkAPI) => {
    const response = await client.get(`program/${id}`)
    if (response.status < 400) {
      if(response.data[0].days.length === 0 ) {
        return { program: undefined, private: response.data[0].private }
      }
      // Launch for week
      const data = weekComplete.map((item) => {
        const mealsOfDay: string[] = response.data[0].days.find((day: any) => day.key === item)?.meals
        if(mealsOfDay)
          //@ts-ignore
          return { ids: mealsOfDay, key: item }
        return null
      }).filter((item) => item !== null)

      //@ts-ignore
      await thunkAPI.dispatch(getMealsDays({ids: data, days: response.data[0].days}))
      await thunkAPI.dispatch(getAuthor({ id: response.data[0].author }))
      return { program: response.data[0], private: response.data[0].private, author: response.data[0].author }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const getMeals = createAsyncThunk(
  'programPublic/getMeals',
  async ({ids, program}: { ids: string[], program: any }, thunkAPI) => {
    if(ids.length === 0) return { program, meals: [] }
    const response = await client.get(`meal/${ids.join(',')}`)
    if (response.status < 400) {
      return { program, meals: response.data }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const getAuthor = createAsyncThunk(
  'programPublic/getAuthor',
  async (payload: {id: string}, thunkAPI) => {
    const response = await client.get(`users/${payload.id}`)
    if (response.status === 200) {
      return { user: response.data }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const getMealsDays = createAsyncThunk(
  'programPublic/getMealsDays',
  async ({ids, days}: { ids: { key: string, ids: string[] }[], days: any }, thunkAPI) => {
    const response = await Promise.all(ids.map(async (data) => {
      if(data.ids.length > 0) {
        const response = await client.get(`meal/${data.ids.join(',')}`)
        let newDays: any = {}
        if (response.status < 400) {
          const findDay = days.find((day: any) => day.key === data.key)
          const isMealsId = Array.isArray(response.data) && response.data.every((meal: any) => typeof meal === 'string')
          const meals = !isMealsId && Array.isArray(response.data) ? transformArrayByObjectByKey<MealState, '_id'>(response.data, '_id') : response.data 
          newDays = {
            ...findDay,
            meals
          }
        }
        return newDays
      }
      return null
    }))
    return await {days: response.filter((day) => day !== null)}
  }
)

export const getMealsDay = createAsyncThunk(
  'programPublic/getMealsDay',
  async ({ids, key, days}: { ids: string[], key: string, days: any }, thunkAPI) => {
    const response = await client.get(`meal/${ids.join(',')}`)
    if (response.status < 400) {
      return { meals: response.data, days, key }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

// Reducers
export const programPublicSlice = createSlice({
  name: 'programPublic',
  initialState,
  reducers: {
    changeActiveDay: (state, action: PayloadAction<{ day?: string }>) => {
      const key = action.payload.day ?? dayjs().format('DD/MM/YYYY')
      return state = {
        ...state,
        activeDay: key,
        days: state.days
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeals.fulfilled, (state, {payload}) => {
      if(!payload.program) return
      const date = dayjs().format('DD/MM/YYYY')
      const activeDayArray = (payload.program.days.find((day: any) => day.key === date) || payload.program.days[payload.program.days.length -1]).key
      const addMeal = payload.program.days.map((day: any) => {
        if(day.key === activeDayArray) {
          return {
            ...day,
            meals: payload.meals
          }
        }
        return day
      })
      const mealsClean = addMeal.map((day: any) => { 
        const IsMealsId = Array.isArray(day.meals) && day.meals.every((meal: any) => typeof meal === 'string')
        return { ...day, meals: !IsMealsId ? transformArrayByObjectByKey<MealState, '_id'>(day.meals, '_id') : day.meals }})
      const days = transformArrayByObjectByKey<Day, 'key'>(mealsClean, 'key')
      state.activeDay = activeDayArray
      state.days = days
    })
    builder.addCase(getAuthor.fulfilled, (state, {payload}) => {
      state.author = payload.user
    })
    builder.addCase(getProgram.fulfilled, (state, {payload}) => {
      state.private = payload.private
      state.authorId = payload.author
    })
    builder.addCase(getMealsDay.fulfilled, (state, {payload}) => {
      const addMeal = Object.values(payload.days).map((day: any) => {
        if(day.key === payload.key) {
          return {
            ...day,
            meals: payload.meals
          }
        }
        return {...day}
      })
      const mealsClean = addMeal.map((day: any) => { 
        const isMealsId = Array.isArray(day.meals) && day.meals.every((meal: any) => typeof meal === 'string')
        return { ...day, meals: !isMealsId && Array.isArray(day.meals) ? transformArrayByObjectByKey<MealState, '_id'>(day.meals, '_id') : day.meals }})
      const days = transformArrayByObjectByKey<Day, 'key'>(mealsClean, 'key')
      state.days = {
        ...state.days,
        [payload.key]: days[payload.key]
      }
    })
    builder.addCase(getMealsDays.fulfilled, (state, {payload}) => {
      const days = transformArrayByObjectByKey<Day, 'key'>(payload.days, 'key')
      state.days = {
        ...state.days,
        //@ts-ignore
        ...days
      }
    })
  }
})

// Export actions
export const { changeActiveDay } = programPublicSlice.actions

// Selectors
export const selectStateActiveDay = (state: RootState) => state.programPublic.activeDay
export const selectDays = (state: RootState) => state.programPublic.days
export const selectProgram = (state: RootState) => state.programPublic
export const selectAuthor = (state: RootState) => state.programPublic.author

export const selectActiveDay = (state: RootState) => state.program.days[state.program.activeDay]


export default programPublicSlice.reducer
