import { FC, useState } from 'react'

import { Text, Avatar, Button } from 'components'
import { COLORS, Colors, FontFamilies, FontWeights } from 'shared/style/constants'

import { StyledHeader } from './core/header.styles'
import { IProps } from './core/header.types'
import { Menu as MenuIcon, X } from 'react-feather'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Menu, Dropdown } from 'antd'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import { logout, userSelector } from 'shared/user/userSlice'
import { setAuthToken } from 'shared/helpers/setHeaderAuthToken'
import Logo from 'shared/assets/logo.svg'

export const Header: FC<IProps> = props => {
  const { className } = props
  const location = useLocation()
  const navigate = useNavigate()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const { user } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const menu = (
    <Menu
      onClick={({ item, key }) => {
        if (key === 'logout') {
          setAuthToken(undefined)
          localStorage.removeItem('token')
          dispatch(logout())
          navigate('/login')
        }
      }}
      items={[
        {
          label: user?.username,
          key: 'fullname'
        },
        {
          label: 'Se déconnecter',
          key: 'logout'
        }
      ]}
    />
  )

  return (
    <StyledHeader {...props} className={`${className || ''} pt-3 pb-3 pl-4 pr-4 f f-ai-center f-jc-space-b`}>
      <div className="logo f f-center">
        <img src={Logo} className="mr-1" />
      </div>
      <div className="navbar">
        <Link to="/">
          <div className={location.pathname === '/' ? 'nav-link active' : 'nav-link'}>Dashboard</div>
        </Link>
        <Link to="/program">
          <div className={location.pathname === '/program' ? 'nav-link active' : 'nav-link'}>Mon programme</div>
        </Link>
      </div>
      <div className="f profile">
        <Dropdown overlay={menu} trigger={['click']}>
          <Avatar username={user?.username} />
        </Dropdown>
      </div>
      <div onClick={() => setMenuIsOpen(!menuIsOpen)} className="icon-menu">
        <MenuIcon size={34} color={COLORS.primary} />
      </div>
      <div className={`navbar-responsive ${menuIsOpen ? 'active' : ''}`}>
        <div onClick={() => setMenuIsOpen(!menuIsOpen)} className="close">
          <X size={28} />
        </div>
        <div className="list">
          <Link to="/">
            <div className={location.pathname === '/' ? 'nav-link active' : 'nav-link'}>Dashboard</div>
          </Link>
          <Link to="/program">
            <div className={location.pathname === '/program' ? 'nav-link active' : 'nav-link'}>Mon programme</div>
          </Link>
          <div className="nav-link">
            <Avatar username={user?.username} />
          </div>
        </div>
      </div>
    </StyledHeader>
  )
}
