import dayjs from 'dayjs'
import { stringify } from 'yaml'


export const debounce = (fn: any, ms: number) => {
  let timeoutId: ReturnType<typeof setTimeout>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

export function normalizeStat(value: number | string, suffix: 'kcal' | 'g' = 'g', serving_quantity?: '' | number, quantity?: number) {
  if (typeof value === 'string' || value === undefined || value === null) {
    return 0 + 'g'
  }
  if (serving_quantity !== '' && serving_quantity) {
    return quantity ? (value * serving_quantity / 100 * quantity).toFixed(0) + suffix : (value * serving_quantity / 100).toFixed(0) + suffix
  }
  return quantity ? (value * quantity).toFixed(0) + suffix : value.toFixed(0) + suffix 
}

export const getInitials = (str?: string) => {
  if(!str) return ''
  return str
    .split(' ')
    .map((word) => word.charAt(0))
    .join('')
    .toUpperCase()
}
export const download = (filename: string, text: string) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export const getYaml = (object: unknown) => {
  return stringify(object, { simpleKeys: true })
}

export const copyToClipboard = (text: string) => {
  const clipboard = navigator.clipboard
  return clipboard.writeText(text)
}

export const hideStorybookControl = {
  table: {
    disable: true,
  },
}

export const formatDate = (date: string) =>
  dayjs(date).format('DD/MM/YYYY - HH:mm')

  
export function transformArrayByObjectByKey<T extends Record<string, unknown>, U extends keyof T>(
  data: Array<T>,
  key: U
): Record<string, T> {
  return data.reduce<Record<string, T>>((acc, item) => {
    const index = item[key]
    acc[index as string] = {
      ...item
    }
    return acc
  }, {})
}
  
  