import { Text } from 'components/Text'
import { FC } from 'react'
import { FontFamilies, FontSizes } from 'shared/style/constants'

import { StyledMealPublic } from './core/meal.styles'
import { IProps } from './core/meal.types'
import { FoodRowPublic } from 'components/FoodRowPublic'

export const MealPublic: FC<IProps> = props => {
  const { meal } = props
  return (
    <StyledMealPublic>
      <div className="header">
        <div className="overall">
          <Text size={FontSizes.MEDIUM} font={FontFamilies.POPPINS}>
            {meal.name}
          </Text>
        </div>
      </div>
      <div className="border"></div>
      {meal.foods && meal.foods.map(food => <FoodRowPublic key={food.food.code} food={food} />)}
    </StyledMealPublic>
  )
}
