import { FC, useEffect, useState } from 'react'

import { StyledDateLinePublic, StyledDate } from './core/dateLine.styles'
import { IProps } from './core/dateLine.types'
import dayjs from 'dayjs'
import { ChevronLeft, ChevronRight } from 'react-feather'
import * as fr from 'dayjs/locale/fr'
import { useAppSelector, useAppDispatch } from 'shared/hooks/store'
import { useDate } from './core/dateLine.hooks'
import {
  selectDays,
  changeActiveDay,
  selectStateActiveDay,
  createProgramDay,
  getMeals,
  getMealsDay
} from 'pages/Program/core/programSlice'

export const DateLinePublic: FC<IProps> = props => {
  dayjs.locale(fr)
  const { activeWeek, weekNumber, setWeekNumber, activeMonthYear } = props
  const program = useAppSelector(selectDays)
  const activeDay = useAppSelector(selectStateActiveDay)
  const dispatch = useAppDispatch()
  return (
    <StyledDateLinePublic>
      <div className="days">
        <div onClick={() => setWeekNumber(weekNumber - 7)} className="round prev">
          <ChevronLeft />
        </div>
        {activeWeek.map((day: any) => (
          <StyledDate
            kcal={program[day.completeDate] ? program[day.completeDate].overall.calories.toString() : '0'}
            key={day.dayNumber}
            onClick={() => {
              if (program[day.completeDate]) {
                dispatch(
                  //@ts-ignore
                  getMealsDay({ ids: program[day.completeDate].meals, key: day.completeDate, days: program })
                )
                dispatch(changeActiveDay({ day: day.completeDate }))
              } else {
                dispatch(createProgramDay({ day: day.completeDate }))
              }
            }}
            className={'round day'}
          >
            <div className="number">{day.dayNumber}</div>
            <div className="name-day">{day.dayString}</div>
          </StyledDate>
        ))}
        <div onClick={() => setWeekNumber(weekNumber + 7)} className="round next">
          <ChevronRight />
        </div>
      </div>
      <div className="year-date">
        <div className="month">{activeMonthYear.month}</div>
        <div className="year">{activeMonthYear.year}</div>
      </div>
    </StyledDateLinePublic>
  )
}
