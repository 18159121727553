import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import dayjs from 'dayjs'
import client from 'shared/helpers/client'
import { transformArrayByObjectByKey } from 'shared/helpers/utils'
import { RootState, AppThunk } from 'shared/store'
import { loadUser } from 'shared/user/userSlice'
import { v4 as uuidv4 } from 'uuid'

export type ProgramSlice = {   
  copy: {
    foods: MealState[number]['foods']
    enabled: boolean
    idActiveMeal: string | null
  }; 
  mealIdActive: string | null,  
  sidebarHidden: boolean,  
  activeDay: string, 
  private: boolean, 
  days: Record<string, Day> 
}

export type Day = {
  meals: MealState // For the moment
  hasBeenEdited?: boolean
  key: string
  overall: Overall
}

export type MealState = Record<string, {
    name: string
    _id: string | null
    createdAt: Date
    overall: Overall
    foods: {
      food: any
      quantity: number
      size: number
    }[]
}>

type Overall = {
  calories: number
  proteins: number
  fat: number
  carbs: number
}

const initialState: ProgramSlice = {
  activeDay: '',
  days: {},
  private: true,
  sidebarHidden: true,
  mealIdActive: null,
  copy: {
    idActiveMeal: null,
    foods: [],
    enabled: false
  }
}

function convertWithSizeAndQuantity(value: number, size: number, quantity: number) {
  return +((value * size / 100) * quantity).toFixed(0)
} 


export const createProgram = createAsyncThunk(
  'program/createProgram',
  async ({author}: { author: string }, thunkAPI) => {
    const response = await client.post('program', {
      name: 'Programme',
      author,
      days: [],
      private: true
    })
    if (response.status === 201) {
      thunkAPI.dispatch(loadUser())
      return { program: response.data }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const createFood = createAsyncThunk(
  'program/createFood',
  async ({data}: { data: any }, thunkAPI) => {
    const response = await client.post('meal/food', data)
    if (response.status < 400) {
      return { food: response.data }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const getProgram = createAsyncThunk(
  'program/getProgram',
  async ({id}: { id: string }, thunkAPI) => {
    const response = await client.get(`program/${id}`)
    if (response.status < 400) {
      if(response.data[0].days.length === 0 ) {
        thunkAPI.dispatch(createProgramDay({}))
        return { program: undefined, private: response.data[0].private }
      }
      const date = dayjs().format('DD/MM/YYYY')
      const activeDay = response.data[0].days.find((day: any) => day.key === date) ? date : (Object.values(response.data[0].days)[(Object.keys(response.data[0].days).length-1)] as any).key
      const mealsOfDay = response.data[0].days.find((day: any) => day.key === activeDay).meals
      thunkAPI.dispatch(getMeals({ids: mealsOfDay, program: response.data[0]}))
      return { program: response.data[0], private: response.data[0].private }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const updateProgram = createAsyncThunk(
  'program/updateProgram',
  async ({id, days, isPrivate}: { id: string, days?: Day[], isPrivate: boolean }, thunkAPI) => {
    const cleanDays = days?.map((day) => {
      return {...day, meals: [...Object.keys(day.meals)]}
    })
    const response = await client.put(`program/${id}`, {
      days: cleanDays,
      private: isPrivate
    })
    if (response.status < 400 && days) {
      const meals = days.map(day => Array.isArray(day.meals) ? day.meals : Object.values(day.meals))
        .flat().filter((day) => typeof day !== 'string')
      //@ts-ignore
      thunkAPI.dispatch(updateMeals({meals}))
      return 
    }
    if(response.status < 400) {
      thunkAPI.dispatch(getProgram({ id: id }))
      return
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const updateMeals = createAsyncThunk(
  'program/updateMeals',
  async ({meals}: { meals: MealState[number][] }, thunkAPI) => {
    const mealsWithFoodId = meals.map((meal) => {
      return {
        ...meal,
        foods: meal.foods.map((food) => {
          return {
            id: food.food.id,
            type: 'food',
            quantity: food.quantity,
            size: food.size
          }
        })
      }
    })
    const response = await client.post('meal/all',
      mealsWithFoodId
    )
    if (response.status < 400) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const getMeals = createAsyncThunk(
  'program/getMeals',
  async ({ids, program}: { ids: string[], program: any }, thunkAPI) => {
    if(ids.length === 0) return { program, meals: [] }
    const response = await client.get(`meal/${ids.join(',')}`)
    if (response.status < 400) {
      return { program, meals: response.data }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

export const getMealsDay = createAsyncThunk(
  'program/getMealsDay',
  async ({ids, key, days}: { ids: string[], key: string, days: any }, thunkAPI) => {
    const response = await client.get(`meal/${ids.join(',')}`)
    if (response.status < 400) {
      return { meals: response.data, days, key }
    }
    return thunkAPI.rejectWithValue(response.data)
  }
)

// Reducers
export const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    changeSidebarHidden: (state, action: PayloadAction<{ visible: boolean }>) => {
      return state = {
        ...state,
        sidebarHidden: action.payload.visible
      }
    },
    changeActiveDay: (state, action: PayloadAction<{ day?: string }>) => {
      const key = action.payload.day ?? dayjs().format('DD/MM/YYYY')
      return state = {
        ...state,
        activeDay: key,
        days: state.days
      }
    },
    createProgramDay: (state, action: PayloadAction<{ day?: string }>) => {
      const key = action.payload.day ?? dayjs().format('DD/MM/YYYY')
      return state = {
        ...state,
        activeDay: key,
        days: {
          ...state.days,
          [key]: {
            key,
            meals: {},
            overall: {
              calories: 0,
              carbs: 0,
              fat: 0,
              proteins: 0
            }
          }
        }
      }
    },
    createFirstMeal: (state, action: PayloadAction<{ food: any, name?: string }>) => {
      const { food, name } = action.payload
      const id = uuidv4()
      const overallMeal = state.days[state.activeDay].overall
      state.days[state.activeDay] = {
        ...state.days[state.activeDay],
        overall: {
          calories: Math.round(overallMeal.calories + food['energy-kcal_100g']),
          carbs: Math.round(overallMeal.carbs + food.carbohydrates_100g),
          fat: Math.round(overallMeal.fat + food.fat_100g),
          proteins: Math.round(overallMeal.proteins + food.proteins_100g),
        },
        meals: {
          ...state.days[state.activeDay].meals,
          [id]: {
            name: name ?? 'Petit-déjeuner',
            _id: id,
            createdAt: new Date(),
            overall: {
              calories:  Math.round(food['energy-kcal_100g']),
              carbs: Math.round(food.carbohydrates_100g),
              fat: Math.round(food.fat_100g),
              proteins: Math.round(food.proteins_100g)
            },
            foods: [{
              food,
              quantity: 1,
              size: food.serving_quantity !== undefined && food.serving_quantity !== '' ? food.serving_quantity : 100 
            }]
          }
        }
      }
    },
    addFood: (state, action: PayloadAction<{ food: any, idMeal: string | null }>) => {
      const { food, idMeal } = action.payload
      const newFood = {
        food,
        quantity: 1,
        size: food.serving_quantity !== undefined && food.serving_quantity !== '' ? food.serving_quantity : 100 
      }
      if(idMeal === null && state.mealIdActive === null)
        return 
      const idNewMeal = idMeal === null && state.mealIdActive ? state.mealIdActive : idMeal !== null ? idMeal : ''
      const ids = state.days[state.activeDay].meals[idNewMeal].foods.map((food) => food.food.id)
      if(ids.includes(newFood.food.id)) {
        message.error('L\'aliment a déjà été ajouté à ce repas')
        return 
      }
      const actualDayOverall = state.days[state.activeDay].overall
      const actualOverall = state.days[state.activeDay].meals[idNewMeal].overall
      state.mealIdActive = null
      state.days[state.activeDay] = {
        ...state.days[state.activeDay],
        overall: {
          calories: Math.round(actualDayOverall.calories + food['energy-kcal_100g']),
          carbs: Math.round(actualDayOverall.carbs + food.carbohydrates_100g),
          fat: Math.round(actualDayOverall.fat + food.fat_100g),
          proteins: Math.round(actualDayOverall.proteins + food.proteins_100g)
        },
        meals:{
          ...state.days[state.activeDay].meals,
          [idNewMeal]: {
            ...state.days[state.activeDay].meals[idNewMeal],
            overall: {
              calories: Math.round(actualOverall.calories + food['energy-kcal_100g']),
              carbs: Math.round(actualOverall.carbs + food.carbohydrates_100g),
              fat: Math.round(actualOverall.fat + food.fat_100g),
              proteins: Math.round(actualOverall.proteins + food.proteins_100g)
            },
            foods: [...state.days[state.activeDay].meals[idNewMeal].foods, newFood]
          }
        }
      }
    },
    calculateStat: (state) => {
      let overallMeal = {
        calories: 0,
        carbs: 0,
        fat: 0,
        proteins: 0
      }
      const meals = Object.values(state.days[state.activeDay].meals).map((meal) => {
        let overall = {
          calories: 0,
          carbs: 0,
          fat: 0,
          proteins: 0
        }
        console.log('foods',meal.foods.length)
        meal.foods.forEach((food) => {
          overall = {
            calories: Math.round(overall.calories + convertWithSizeAndQuantity(food.food['energy-kcal_100g'],food.size,food.quantity)),
            carbs: Math.round(overall.carbs + convertWithSizeAndQuantity(food.food.carbohydrates_100g ,food.size ,food.quantity)),
            fat: Math.round(overall.fat + convertWithSizeAndQuantity(food.food.fat_100g, food.size, food.quantity)),
            proteins: Math.round(overall.proteins + convertWithSizeAndQuantity(food.food.proteins_100g, food.size, food.quantity)) 
          }
        })
        return {
          ...meal,
          overall
        }
      })
      meals.forEach((meal) => meal.foods.forEach((food) => {
        overallMeal = {
          calories: Math.round(overallMeal.calories + convertWithSizeAndQuantity(food.food['energy-kcal_100g'],food.size,food.quantity)),
          carbs: Math.round(overallMeal.carbs + convertWithSizeAndQuantity(food.food.carbohydrates_100g ,food.size ,food.quantity)),
          fat: Math.round(overallMeal.fat + convertWithSizeAndQuantity(food.food.fat_100g, food.size, food.quantity)),
          proteins: Math.round(overallMeal.proteins + convertWithSizeAndQuantity(food.food.proteins_100g, food.size, food.quantity))
        }}))
      state.days[state.activeDay] = {
        ...state.days[state.activeDay],
        overall: overallMeal,
        meals: meals.reduce((acc: any, key) => (acc[key._id ?? 'cc'] = key, acc), {})
      }
    },
    deleteFood: (state, action: PayloadAction<{ food: any, idMeal: string | null }>) => {
      const { food, idMeal } = action.payload
      if(idMeal === null)
        return 
      const actualDayOverall = state.days[state.activeDay].overall
      const actualOverall = state.days[state.activeDay].meals[idMeal].overall
      state.days[state.activeDay] = {
        ...state.days[state.activeDay],
        overall: {
          calories: Math.abs(actualDayOverall.calories - convertWithSizeAndQuantity(food.food['energy-kcal_100g'], food.size, food.quantity)),
          carbs: Math.abs(actualDayOverall.carbs - convertWithSizeAndQuantity(food.food.carbohydrates_100g ,food.size ,food.quantity)),
          fat: Math.abs(actualDayOverall.fat - convertWithSizeAndQuantity(food.food.fat_100g, food.size, food.quantity)),
          proteins: Math.abs(actualDayOverall.proteins - convertWithSizeAndQuantity(food.food.proteins_100g, food.size, food.quantity))
        },
        meals:{
          ...state.days[state.activeDay].meals,
          [idMeal]: {
            ...state.days[state.activeDay].meals[idMeal],
            overall: {
              calories: Math.abs(actualOverall.calories - convertWithSizeAndQuantity(food.food['energy-kcal_100g'], food.size, food.quantity)),
              carbs: Math.abs(actualOverall.carbs - convertWithSizeAndQuantity(food.food.carbohydrates_100g ,food.size ,food.quantity)),
              fat: Math.abs(actualOverall.fat - convertWithSizeAndQuantity(food.food.fat_100g, food.size, food.quantity)),
              proteins: Math.abs(actualOverall.proteins - convertWithSizeAndQuantity(food.food.proteins_100g, food.size, food.quantity))
            },
            foods: state.days[state.activeDay].meals[idMeal].foods.filter((foodToFilter) => foodToFilter.food.id !== food.food.id)
          }
        }
      }
    },
    createMeal: (state, action: PayloadAction<{ id?: string, name: string }>) => {
      const id = action.payload.id ?? uuidv4()
      state.days[state.activeDay].meals[id] = {
        name: action.payload.name,
        _id: id,
        createdAt: new Date(),
        overall: {
          calories: 0,
          proteins: 0,
          fat: 0,
          carbs: 0,
        },
        foods: []
      }
    },
    deleteMeal: (state, action: PayloadAction<{ meal: MealState[number] }>) => {
      const { meal } = action.payload
      state.days[state.activeDay] = {
        ...state.days[state.activeDay],
        overall: {
          calories: Math.round(state.days[state.activeDay].overall.calories - meal.overall.calories),
          carbs: Math.round(state.days[state.activeDay].overall.carbs - meal.overall.carbs),
          fat: Math.round(state.days[state.activeDay].overall.fat - meal.overall.fat),
          proteins: Math.round(state.days[state.activeDay].overall.proteins - meal.overall.proteins),
        }
      }
      action.payload.meal._id && delete state.days[state.activeDay].meals[action.payload.meal._id]
    },
    editNameMeal: (state, action: PayloadAction<{ id: string, name: string }>) => {
      state.days[state.activeDay].meals[action.payload.id].name = action.payload.name
    },
    chooseMeal: (state, action: PayloadAction<{ id: string }>) => {
      state.mealIdActive = action.payload.id
    },
    copy: (state, action: PayloadAction<{ idMeal: string, foods: any[] }>) => {
      const { idMeal, foods }= action.payload
      state.copy = {
        enabled: true,
        foods: foods,
        idActiveMeal: idMeal,
      }
      message.success('Les aliments de ce repas ont été copié')
    },
    paste: (state, action: PayloadAction<{ idMeal: string }>) => {
      const { idMeal }= action.payload
      state.days[state.activeDay].meals[idMeal].foods = [...state.days[state.activeDay].meals[idMeal].foods, ...state.copy.foods]
      state.copy = {
        enabled: false,
        foods: [],
        idActiveMeal: null,
      }
    },
    handleChangeFood: (state, action: PayloadAction<{ key: 'size' | 'quantity', value: number, foodId: string, mealId: string  }>) => {
      const { key, value, foodId, mealId } = action.payload
      state.days[state.activeDay].meals[mealId].foods = state.days[state.activeDay].meals[mealId].foods.map((food) => {
        if(food.food.id === foodId) {
          return {
            ...food,
            [key]: value
          }
        }
        return food
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeals.fulfilled, (state, {payload}) => {
      if(!payload.program) return
      const date = dayjs().format('DD/MM/YYYY')
      const activeDayArray = (payload.program.days.find((day: any) => day.key === date) || payload.program.days[payload.program.days.length -1]).key
      const addMeal = payload.program.days.map((day: any) => {
        if(day.key === activeDayArray) {
          return {
            ...day,
            meals: payload.meals
          }
        }
        return day
      })
      const mealsClean = addMeal.map((day: any) => { 
        const IsMealsId = Array.isArray(day.meals) && day.meals.every((meal: any) => typeof meal === 'string')
        return { ...day, meals: !IsMealsId ? transformArrayByObjectByKey<MealState, '_id'>(day.meals, '_id') : day.meals }})
      const days = transformArrayByObjectByKey<Day, 'key'>(mealsClean, 'key')
      state.activeDay = activeDayArray
      state.days = days
    })
    builder.addCase(updateMeals.fulfilled, (state, {payload}) => {
      message.success('Programme mis à jour avec succès')
    })
    builder.addCase(createFood.fulfilled, (state, {payload}) => {
      message.success('L\'aliment a été ajouté avec succès')
    })
    builder.addCase(getProgram.fulfilled, (state, {payload}) => {
      state.private = payload.private
    })
    builder.addCase(getMealsDay.fulfilled, (state, {payload}) => {
      const addMeal = Object.values(payload.days).map((day: any) => {
        if(day.key === payload.key) {
          return {
            ...day,
            meals: payload.meals
          }
        }
        return {...day}
      })
      const mealsClean = addMeal.map((day: any) => { 
        const isMealsId = Array.isArray(day.meals) && day.meals.every((meal: any) => typeof meal === 'string')
        return { ...day, meals: !isMealsId && Array.isArray(day.meals) ? transformArrayByObjectByKey<MealState, '_id'>(day.meals, '_id') : day.meals }})
      const days = transformArrayByObjectByKey<Day, 'key'>(mealsClean, 'key')
      state.days = days
    })
  }
})

// Export actions
export const { changeSidebarHidden, copy, paste, createProgramDay, calculateStat, editNameMeal, handleChangeFood, changeActiveDay, addFood, deleteMeal, deleteFood, createMeal, createFirstMeal, chooseMeal } = programSlice.actions

// Selectors
export const selectStateActiveDay = (state: RootState) => state.program.activeDay
export const selectDays = (state: RootState) => state.program.days
export const selectProgram = (state: RootState) => state.program
export const selectHiddenSidebar = (state: RootState) => state.program.sidebarHidden

export const selectActiveDay = (state: RootState) => state.program.days[state.program.activeDay]


export default programSlice.reducer
