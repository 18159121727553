import styled from 'styled-components'

export const StyledMainLayout = styled.div`
height: 100vh;
overflow: hidden;

.sidebar {
    width: 25vw;
    flex: 0 0 0;
}

.main {
    flex: 1 0 0;
    overflow: hidden;
}

.content {
    flex: 1 1 auto;
    overflow-y: scroll;
    @media screen and (max-width: 600px) {
        &.p-2 {
            padding: 0;
        }
    }
}
`
