import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'
import RightSide from 'shared/assets/register.jpg'


export const StyledLogin = styled['div']`
    display: flex;
    background: ${COLORS.grey1};
    height: 100vh;

    .left-side {
        height: 100vh;
        background: #fff;
        width: 40vw;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 600px) {
            width: 100vw;
        }
    }

    .box {
        width: 70%;
        padding: 30px;
        background: #fff;
        border-radius: 10px;
        @media screen and (max-width: 600px) {
            width: 90%;
        }
    }

    .right-side {
        width: 60vw;
        background-size: cover;
        background-image: url(${RightSide});
        img {
        }
        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    .w-100 {
        width: 100%;
        height: 50px;
    }

    .link {
        color: ${COLORS.primary};
    }

    .logo {
        .text {
            font-size: 32px;
            font-family:"Poppins";
            padding-top: 10px;
            font-weight: 500;
            color: ${COLORS.primary};
        }
    }

    .not-register {
        text-align: center;
        font-weight: 600;
        font-size: 17px;
        margin-top: 20px;
    }

    .error {
        color: ${COLORS.DELETE}
    }
`