import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'



export const StyledMealPublic = styled['div']`
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgb(34 34 87 / 5%);
  border-radius: 10px;
  padding: 18px;
  transition: .2s ease-in-out;
  margin: 20px 0 0;

  .border {
    width: 100%;
    height: 1px;
    margin-top: 18px;
    background: ${COLORS.grey2};
  }

  .delete {
    background: ${COLORS.DELETE};
    border-radius: 6px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product_name {
    width:100%;
  }

  .overall {
    display: flex;
    align-items: center;    
    justify-content: space-between;
  }

`
