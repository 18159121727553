import { FC } from 'react'
import { Text } from 'components/Text'

import { FontWeights } from 'shared/style/constants'

import { StyledScore } from './core/score.styles'
import { IProps } from './core/score.types'

export const Score: FC<IProps> = props => {
  const { stats, hiddenDetails } = props

  return (
    <StyledScore className={'group-overall'}>
      <div className="balise">
        <Text weight={FontWeights.BOLD} className="number">
          {stats.calories}
        </Text>
        <Text className="c upper">{window.innerWidth < 600 ? 'kcal' : 'calories'}</Text>
      </div>
      {console.log(window.innerWidth)}
      <>
        <div className="balise">
          {window.innerWidth < 600 && <Text className="p upper"> P:</Text>}
          <Text weight={FontWeights.BOLD} className="number">
            {stats.proteins}
          </Text>
          <Text className="p upper">{window.innerWidth > 600 && 'protéines'}</Text>
        </div>
        <div className="balise">
          {window.innerWidth < 600 && <Text className="g upper"> G:</Text>}
          <Text weight={FontWeights.BOLD} className="number">
            {stats.carbs}
          </Text>
          <Text className="g upper">{window.innerWidth > 600 && 'glucides'}</Text>
        </div>
        <div className="balise">
          {window.innerWidth < 600 && <Text className="l upper">L:</Text>}
          <Text weight={FontWeights.BOLD} className="number">
            {stats.fat}
          </Text>
          <Text className="l upper">{window.innerWidth > 600 && 'lipides'}</Text>
        </div>
      </>
    </StyledScore>
  )
}
