import { FC, ReactElement, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppDispatch } from 'shared/hooks/store'
import { loadUser } from 'shared/user/userSlice'
import { setAuthToken } from './setHeaderAuthToken'

export const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (token) {
      setAuthToken(token)
    }
    dispatch(loadUser())
  }, [])
  if (!token) {
    return <Navigate to="/login" replace />
  }

  return children
}
