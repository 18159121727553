import { DateLine, Meal, Button, Text } from 'components'
import { FC, useState, useEffect } from 'react'
import { StyledIndex } from './core/index.styles'
import { useDrop } from 'react-dnd'
import { ItemTypes } from 'components/SearchRow/core/searchRow.types'
import {
  selectActiveDay,
  selectProgram,
  selectDays,
  createFirstMeal,
  getProgram,
  updateProgram,
  selectHiddenSidebar,
  changeSidebarHidden
} from './core/programSlice'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import { CreateMeal } from 'components/CreateMeal'
import MainLayout from '../../layouts/MainLayout'
import { Colors, FontSizes } from 'shared/style/constants'
import { Eye, Save } from 'react-feather'
import { userSelector } from 'shared/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { getGoal, selectGoal } from 'pages/Index/core/goalSlice'

const Program: FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(userSelector)
  const navigate = useNavigate()
  const goal = useAppSelector(selectGoal)
  const day = useAppSelector(selectActiveDay)
  const program = useAppSelector(selectProgram)
  const hiddenSidebar = useAppSelector(selectHiddenSidebar)
  const days = useAppSelector(selectDays)
  const [mealIdHover, setMealIdHover] = useState<string | null>(null)
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.FOOD,
      drop: (data: any, monitor) => {
        dispatch(createFirstMeal({ food: data }))
      },
      collect: monitor => ({
        isOver: !!monitor.isOver()
      })
    }),
    [day, mealIdHover]
  )

  useEffect(() => {
    if (Object.keys(days).length === 0 && user && user.programs.length > 0) {
      dispatch(getProgram({ id: user.programs[0] }))
      user.goal && dispatch(getGoal({ id: user.goal }))
    }
  }, [user])

  const getComplementInfo = (key: 'proteins' | 'carbs' | 'fat' | 'calories') => {
    const newValue =
      typeof day.overall[key] === 'string' ? +(day.overall[key] as any).replace(',', '.') : day.overall[key]
    return (
      <div className="line-row mt-4">
        <div>
          {newValue.toFixed(0)}/{goal[key]}
          {key === 'calories' ? 'kcal' : 'g'}
        </div>
        <div>
          {goal[key] - newValue <= 0 ? (
            <span>
              Objectif atteint: {(goal[key] - newValue).toFixed(0)} {key === 'calories' ? 'kcal' : 'g'}{' '}
            </span>
          ) : (
            <span>
              Restant: {(goal[key] - newValue).toFixed(0)} {key === 'calories' ? 'kcal' : 'g'}
            </span>
          )}
        </div>
      </div>
    )
  }

  const calculatedGoal = (key: 'proteins' | 'carbs' | 'fat' | 'calories') => {
    const newValue =
      typeof day.overall[key] === 'string' ? +(day.overall[key] as any).replace(',', '.') : day.overall[key]
    const value = (newValue / goal[key]) * 100
    if (value > 100) return 100
    return value.toFixed(0)
  }

  const calculatedValue = (key: 'proteins' | 'carbs' | 'fat' | 'calories') => {
    const newValue =
      typeof day.overall[key] === 'string' ? +(day.overall[key] as any).replace(',', '.') : day.overall[key]
    const value = (newValue / goal[key]) * 100
    if (value === Infinity) return '+99'
    if (value > 100) return 100
    return value.toFixed(0)
  }

  const mealsIsArray = day && Array.isArray(day.meals)
  return (
    <MainLayout>
      <StyledIndex isOver={isOver}>
        <div className="header-program">
          <div className="box program">
            <div className="f f-jc-space-b">
              <div className="f f-jc-space-b f-ai-center nowrap">
                <Text className="f-jc-space-b" size={FontSizes.MEDIUM}>
                  Programme
                </Text>
                <div onClick={() => user && navigate(`/program/${user.programs[0]}`)} className="ml-3 see">
                  <Eye color="#fff" />
                </div>
              </div>
              <div className="f f-jc center">
                <Button
                  onClick={() =>
                    user &&
                    dispatch(
                      updateProgram({ id: user.programs[0], isPrivate: program.private, days: Object.values(days) })
                    )
                  }
                  mainColor={Colors.PRIMARY}
                  secondaryColor={Colors.WHITE}
                  hover={true}
                  active={false}
                >
                  Enregistrer
                </Button>
              </div>
            </div>
            <DateLine />
          </div>
        </div>
        {day && (
          <div className="meal-list">
            {!mealsIsArray &&
              Object.keys(day.meals).length > 0 &&
              Object.values(day.meals).map(meal => (
                <Meal mealIdHover={mealIdHover} handleHover={id => setMealIdHover(id)} key={meal._id} meal={meal} />
              ))}
            <CreateMeal />
          </div>
        )}
        {day && (
          <div className="box stats">
            <Text size={FontSizes.MEDIUM}>Statistique de la journée</Text>
            <div className="list">
              <div className="box">
                <div className="line-row">
                  <div className="f calories">
                    <div className="round c">C</div>
                    <div className="progress bg">
                      <div style={{ width: calculatedGoal('calories') + '%' }} className="complete-line"></div>
                    </div>
                  </div>
                  <div className="percentage">{calculatedValue('calories')}%</div>
                </div>
                {getComplementInfo('calories')}
              </div>
              <div className="box">
                <div className="line-row">
                  <div className="f proteins">
                    <div className="round p">P</div>
                    <div className="progress bg">
                      <div style={{ width: calculatedGoal('proteins') + '%' }} className="complete-line"></div>
                    </div>
                  </div>
                  <div className="percentage">{calculatedValue('proteins')}%</div>
                </div>
                {getComplementInfo('proteins')}
              </div>
              <div className="box">
                <div className="line-row">
                  <div className="f carbs">
                    <div className="round g">G</div>
                    <div className="progress bg">
                      <div style={{ width: calculatedGoal('carbs') + '%' }} className="complete-line"></div>
                    </div>
                  </div>
                  <div className="percentage">{calculatedValue('carbs')}%</div>
                </div>
                {getComplementInfo('carbs')}
              </div>
              <div className="box">
                <div className="line-row">
                  <div className="f fat">
                    <div className="round l">L</div>
                    <div className="progress bg">
                      <div style={{ width: calculatedGoal('fat') + '%' }} className="complete-line"></div>
                    </div>
                  </div>
                  <div className="percentage">{calculatedValue('fat')}%</div>
                </div>
                {getComplementInfo('fat')}
              </div>
            </div>
          </div>
        )}
        <div
          onClick={() =>
            user &&
            dispatch(updateProgram({ id: user.programs[0], isPrivate: program.private, days: Object.values(days) }))
          }
          className="save-program"
        >
          <Save size={20} /> Sauvegarder mon programme
        </div>
      </StyledIndex>
    </MainLayout>
  )
}

export default Program
