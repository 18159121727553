import styled from 'styled-components'

import { COLORS,  } from 'shared/style/constants'

export const StyledSearchRow = styled('div')`
    background: ${COLORS.grey1};
    padding: 18px;
    margin-bottom: 20px;
    border-radius: 6px;

    .header {
        display: flex;
        margin-bottom: 12px;
    }

    .verif {
        display: inline-block;
    }

    .icon {
        height: 48px;
        width: 48px;
        font-size: 24px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 6px;
        background: ${COLORS.grey2};
        overflow: hidden;
        img {
            background-size: cover;
            width: 100%;
        }
    }

    .content-row {
        margin-left: 12px;
        width: 100%;
    }

    .stats {
        display: flex;
        justify-content: space-between;
    }


    .toggle-more {
        margin: 15px 0 0;
        padding: 15px 15px 0;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        border-top: 1px solid ${COLORS.grey2};
        color: ${COLORS.black};
        text-transform: uppercase;
    }

    .table-more-details {
        background: #f3f3f3;
        padding: 10px 20px;
        margin-top: 12px;
        border-radius: 10px;
    }

    .line {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        border-bottom: 1px solid #ccc;
        .name {
            display: flex;
            align-items: center;
        }
    }

    .subline {
        padding: 5px 0px 5px 25px;
        color: #818181;
    }
`
