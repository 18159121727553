import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'


export const StyledIndex = styled['div']`
  .header-program {
    display: flex;
    justify-content: space-between;
  }

  .line-stat {
    margin-top: 10px;
  }

  .ant-progress-outer {
    width: 50%;
  }

  .days.box {
    display: flex;
    overflow-y:scroll;
    justify-content: space-between;
    
    .day {
      min-width: 20%;
      flex: 1;
      margin-right: 20px;
    }
    .day:last-child {
      margin-right: 0px;
    }
  }
  

  .box {
    padding: 28px;
    border-radius: 10px;
    margin: 24px 24px 0 24px;
    background: ${COLORS.grey1};
  }

  .program {
    flex:1;
  }

  .stats {
    flex:1;
  }

  .meal-list {
    width: auto;
    min-height: calc(100vh - 72px - 120px);

    margin: 24px;
    border-radius: 10px;
    background: ${COLORS.grey1};
    display: flex;
    flex-direction: column;
    .placeholder {
      margin: 20px;
      height: calc(100%- 50px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      border-radius: 10px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='4' stroke-dasharray='20%2c 22' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
    }
    
  }

  .center {
    align-items: center;
  }
`