import { FC } from 'react'


import { StyledTag } from './core/tag.styles'
import { IProps } from './core/tag.types'

export const Tag: FC<IProps> = (props) => {
  const { type, value} = props

  return (
    <StyledTag {...props} className={`${type}`}>
      <div className={`round ${type}`}>{type}</div>
      <div className="value">{value}</div>
    </StyledTag>
  )
}
