import { InputNumber, Select } from 'antd'
import { Score } from 'components/Score'
import { Text } from 'components/Text'
import { FC } from 'react'
import { FontFamilies, FontSizes, FontWeights } from 'shared/style/constants'
import { Copy, X } from 'react-feather'
import { StyledFoodRow } from './core/foodRow.styles'
import { IProps } from './core/foodRow.types.'
import { normalizeStat } from 'shared/helpers/utils'
import { getEmojiFood } from 'shared/helpers/getEmoji'

const { Option } = Select

export const FoodRow: FC<IProps> = props => {
  const { food, deleteFood, handleChangeFood } = props

  function onChange(value: number) {
    handleChangeFood('quantity', +value)
  }

  function handleChange(value: number) {
    handleChangeFood('size', +value)
  }

  return (
    <StyledFoodRow>
      <div className="header">
        <div className="overall">
          <div className="head">
            <div className="icon">{getEmojiFood(food.food.categories_fr)}</div>
            <InputNumber className="input-number" min={0} max={100} defaultValue={food.quantity} onChange={onChange} />
            <InputNumber
              defaultValue={food.size}
              onChange={handleChange}
              addonAfter={
                <Select defaultValue={'g'}>
                  <Option value={'g'}>g</Option>
                </Select>
              }
            />
            <div className="name">
              <Text className="product_name" weight={FontWeights.BOLD} font={FontFamilies.INTER}>
                {food.food.product_name.length > 50
                  ? food.food.product_name.slice(0, 50) + '..'
                  : food.food.product_name}
              </Text>
              <Text
                className="product_name"
                size={FontSizes.SMALL}
                weight={FontWeights.MEDIUM}
                font={FontFamilies.INTER}
              >
                {food.size * food.quantity}g
              </Text>
            </div>
          </div>
          <div className="f f-ai-center">
            <Score
              stats={{
                proteins: normalizeStat(food.food.proteins_100g, 'g', food.size, food.quantity),
                fat: normalizeStat(food.food.fat_100g, 'g', food.size, food.quantity),
                carbs: normalizeStat(food.food.carbohydrates_100g, 'g', food.size, food.quantity),
                calories: normalizeStat(food.food['energy-kcal_100g'], 'kcal', food.size, food.quantity)
              }}
              hiddenDetails={true}
            />
            <div onClick={() => deleteFood(food)} className="delete-food">
              <X color="#fff" />
            </div>
          </div>
        </div>
      </div>
    </StyledFoodRow>
  )
}
