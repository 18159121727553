import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'
import RightSide from 'shared/assets/login.jpg'


export const StyledLogin = styled['div']`
    display: flex;
    background: ${COLORS.grey1};
    height: 100vh;

    @media screen and (max-width: 600px) {
        height:auto;
        width: 100%;
        flex-wrap:wrap;
    }

    .left-side {
        height: 100vh;
        background: #fff;
        width: 40vw;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 600px) {
            width: 100%;
            height: 50vh;
        }
    }

    .box {
        width: 70%;
        padding: 30px;
        background: #fff;
        border-radius: 10px;
        @media screen and (max-width: 600px) {
            width: 90%;
        }
    }

    .right-side {
        width: 60vw;
        background-size: cover;
        background-image: url(${RightSide});
        position: relative;
        img {
        }
        .box-present {
            position: absolute;
            width: 80%;
            top: 50%;
            left: 50%;
            font-size: 16px;
            line-height: 1.4;
            background: #fff;
            transform: translate(-50%, -50%);
            border-radius: 10px;
            padding: 30px;
            font-weight: 500;
            font-family: "Poppins";
            color: #000;
            .title {
                margin-bottom: 12px;
                font-size: 20px;
                color: #d34d10;
                line-height: 1.4;
                @media screen and (max-width: 600px) {
                   font-size: 18px;
                }
            }
        }
        @media screen and (max-width: 600px) {
            height:50vh;
            width: 100%;
        }
    }

    .w-100 {
        width: 100%;
        height: 50px;
    }

    .link {
        color: ${COLORS.primary};
    }

    .logo {
        .text {
            font-size: 32px;
            font-family:"Poppins";
            padding-top: 10px;
            font-weight: 500;
            color: ${COLORS.primary};
        }
    }

    .not-register {
        text-align: center;
        font-weight: 600;
        font-size: 17px;
        margin-top: 20px;
    }

    .error {
        color: ${COLORS.DELETE}
    }
`