import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'shared/store'
import client from 'shared/helpers/client'
import { setAuthToken } from 'shared/helpers/setHeaderAuthToken'
import { createProgram } from 'pages/Program/core/programSlice'

type UserRegisterForm = {
    username: string
    email: string
    password: string
}

type UserLoginForm = {
    email: string
    password: string
}

export type User = {
  password: string
  username: string
  email: string
  goal?: string
  _id: string
  programs: string[]
}

type UserState = {
  user: null | User,
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  errorMessage: string,
}

const initialState: UserState = {
  user: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
}

export const loadUser = createAsyncThunk(
  'user/loadUser',
  async (payload, thunkAPI) => {
    const response = await client.get('users')
    if (response.status === 200) {
      return { user: response.data }
    }
    localStorage.deleteItem('token')
    return thunkAPI.rejectWithValue(response.data)
      
  }
)

export const signUp = createAsyncThunk(
  'user/signUp',
  async ({ username, email, password }: UserRegisterForm, thunkAPI) => {
    try {
      if(password.length < 5) {
        return thunkAPI.rejectWithValue({ message: 'Le mot de passe doit contenir plus de 5 caractères'})
      }
      const response = await client.post('users/create', {
        username,
        email,
        password,
      })
      if (response.status === 201) {
        setAuthToken(response.data.access_token)
        localStorage.setItem('token', response.data.access_token)
        thunkAPI.dispatch(createProgram({ author: response.data.user._id }))
        return { ...response.data, username, email }
      }
    } catch(err) {
      const error: any = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
      
  }
)

export const signIn = createAsyncThunk(
  'user/signIn',
  async ({ email, password }: UserLoginForm, thunkAPI) => {
    const response = await client.post('auth/login', {
      email,
      password,
    }

    )
    if (response.status === 201) {
      setAuthToken(response.data.access_token)
      localStorage.setItem('token', response.data.access_token)
      return { ...response.data, email }
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.isSuccess = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.fulfilled, (state, {payload}) => {
      localStorage.setItem('token', payload.access_token)
      state.isLoading = false
      state.isSuccess = true
      state.isError = false
      state.user = payload.user
    })
    builder.addCase(loadUser.fulfilled, (state, { payload }) => {
      state.user = payload.user
    })
    builder.addCase(signUp.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(signUp.rejected, (state, { payload }) => {
      state.errorMessage = (payload as any).message
      state.isLoading = false
      state.isError = true
    })
    builder.addCase(signIn.fulfilled, (state, {payload}) => {
      state.isLoading = false
      state.isSuccess = true
      state.isError = false
    })
    builder.addCase(signIn.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(signIn.rejected, (state, { payload }) => {
      state.isLoading = false
      state.isError = true
    })
  },
})


// Export actions
export const { logout } = userSlice.actions


export const userSelector = (state: RootState) => state.user

export default userSlice.reducer
