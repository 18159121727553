import dayjs from 'dayjs'
import {  useEffect, useState } from 'react'
import * as fr from 'dayjs/locale/fr'

export function useDate() {
  dayjs.locale(fr)
  const [lastSevenDays, setLastSevenDays] = useState<{ dayString: string, completeDate: string }[]>([])
  const [activeWeek, setActiveWeek] = useState<{ dayNumber: number; dayString: string, completeDate: string }[]>([])
  const [weekNumber, setWeekNumber] = useState(dayjs().format('ddd') === 'dim.' ? -1 : 0)
  const [activeMonthYear, setActiveMonthYear] = useState<{ month?: string; year?: string }>({})

  useEffect(() => {
    const days: any = [{ dayString: dayjs().format('ddd'), completeDate: dayjs().format('DD/MM/YYYY')}]
    for (let i = 1; i <= 6; i++) {
      const completeDate = dayjs().subtract(i, 'day').format('DD/MM/YYYY')
      const dayString = dayjs().subtract(i, 'day').format('ddd')
      days.push({ dayString, completeDate })
    }
    setLastSevenDays(days)
  }, [])

  useEffect(() => {
    const days = []
    for (let i = 1; i <= 7; i++) {
      if (weekNumber <= 0) {
        const completeDate = dayjs().subtract(Math.abs(weekNumber), 'day').day(i).format('DD/MM/YYYY')
        const dayNumber = dayjs().subtract(Math.abs(weekNumber), 'day').day(i).format('D')
        const dayString = dayjs().subtract(Math.abs(weekNumber), 'day').day(i).format('ddd')
        const month = dayjs().subtract(Math.abs(weekNumber), 'day').day(i).format('MMM')
        const year = dayjs().subtract(Math.abs(weekNumber), 'day').day(i).format('YYYY')
        days.push({ dayNumber: +dayNumber, dayString, completeDate })
        setActiveMonthYear({ month, year })
      } else {
        const completeDate = dayjs().add(weekNumber, 'day').day(i).format('DD/MM/YYYY')
        const dayNumber = dayjs().add(weekNumber, 'day').day(i).format('D')
        const dayString = dayjs().add(weekNumber, 'day').day(i).format('ddd')
        const month = dayjs().add(weekNumber, 'day').day(i).format('MMM')
        const year = dayjs().add(weekNumber, 'day').day(i).format('YYYY')
        days.push({ dayNumber: +dayNumber, dayString, completeDate })
        setActiveMonthYear({ month, year })
      }
    }
    setActiveWeek(days)
  }, [weekNumber])

  return { lastSevenDays, activeWeek, weekNumber, setWeekNumber, activeMonthYear }
}