import { FoodRow } from 'components/FoodRow'
import { Score } from 'components/Score'
import { Text } from 'components/Text'
import { FC, useEffect } from 'react'
import { Colors, COLORS, FontFamilies, FontSizes } from 'shared/style/constants'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import {
  addFood,
  selectActiveDay,
  handleChangeFood,
  createFirstMeal,
  deleteFood,
  calculateStat,
  deleteMeal,
  editNameMeal,
  copy,
  paste,
  selectProgram,
  chooseMeal,
  changeSidebarHidden
} from 'pages/Program/core/programSlice'
import { Plus } from 'react-feather'
import { ItemTypes } from 'components/SearchRow/core/searchRow.types'
import { useDrop } from 'react-dnd'
import { normalizeStat } from 'shared/helpers/utils'

import { StyledMeal } from './core/meal.styles'
import { IProps } from './core/meal.types'
import { Copy, X } from 'react-feather'

export const Meal: FC<IProps> = props => {
  const { meal, handleHover, mealIdHover } = props
  const day = useAppSelector(selectActiveDay)
  const program = useAppSelector(selectProgram)
  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: ItemTypes.FOOD,
      drop: (data: any, monitor) => {
        const didDrop = monitor.didDrop()
        if (didDrop) {
          return
        }
        Object.keys(day.meals).length === 0
          ? dispatch(createFirstMeal({ food: data }))
          : dispatch(addFood({ food: data, idMeal: mealIdHover }))
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true })
      })
    }),
    [mealIdHover]
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (meal._id !== null && isOverCurrent) {
      handleHover(meal._id)
    }
  }, [isOverCurrent])

  return (
    <StyledMeal ref={drop} isOverCurrent={isOverCurrent}>
      <div className="header">
        <div className="overall">
          <input
            value={meal.name}
            onChange={e => meal._id && dispatch(editNameMeal({ id: meal._id, name: e.target.value }))}
            className="outline-input"
          />
          <div className="f f-ai-center">
            <Score
              stats={{
                proteins: normalizeStat(meal.overall.proteins),
                fat: normalizeStat(meal.overall.fat),
                calories: normalizeStat(meal.overall.calories, 'kcal'),
                carbs: normalizeStat(meal.overall.carbs)
              }}
            />
            <div
              onClick={() => {
                if (meal._id && meal._id !== program.copy.idActiveMeal) {
                  if (program.copy.enabled) {
                    dispatch(paste({ idMeal: meal._id }))
                  } else {
                    dispatch(copy({ idMeal: meal._id, foods: meal.foods }))
                  }
                  dispatch(calculateStat())
                }
              }}
              className={!program.copy.enabled || program.copy.idActiveMeal === meal._id ? 'copy' : 'paste active'}
            >
              <Copy color="#fff" />
            </div>
            <div onClick={() => meal._id && dispatch(deleteMeal({ meal }))} className="delete">
              <X color="#fff" />
            </div>
          </div>
        </div>
      </div>
      <div className="border"></div>
      <div
        onClick={() => {
          meal._id && dispatch(chooseMeal({ id: meal._id }))
          dispatch(changeSidebarHidden({ visible: false }))
        }}
        className="create-food"
      >
        <div className="round">
          <Plus size={16} color={COLORS.primary} />
        </div>
        <Text color={Colors.WHITE} size={FontSizes.MEDIUM}>
          Ajouter un aliment
        </Text>
      </div>
      {meal.foods.map(food => (
        <FoodRow
          key={food.food.code}
          food={food}
          handleChangeFood={(key, value) => {
            meal._id && dispatch(handleChangeFood({ key, value, foodId: food.food.id, mealId: meal._id }))
            dispatch(calculateStat())
          }}
          deleteFood={food => dispatch(deleteFood({ idMeal: meal._id, food: food }))}
        />
      ))}
    </StyledMeal>
  )
}
