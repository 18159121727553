import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'
import { IProps } from './weekBar.types'

type IStyledWeekBar = {
    type?: IProps['type']
}

export const StyledWeekBar = styled['div']<IStyledWeekBar>`
    display: flex;
    height: 140px;
    justify-content: space-between;
    position: relative;
    .day {
        width: 30px;
        position: relative;
    }
    .day-name {
        position: absolute;
        bottom: -20px;
    }
    .bg {
        position: relative;
        border-radius: 4px;
        background: red;
        width: 30px;
        height: 100%;
    }
    .complete-line {
        position: absolute;
        bottom:0;
        width: 30px;
        height: 0%;
        border-radius: 4px;
        background: blue;
    }

    &.proteins {
        .bg {
            background: #3498DB30;
        }
        .complete-line {
            background: #3498DB;
        }
    }
    &.fat {
        .bg {
            background: #1ABC9C30;
        }
        .complete-line {
            background: #1ABC9C;
        }
    }
    &.carbs {
        .bg {
            background: #9B59B630;
        }
        .complete-line {
            background: #9B59B6;
        }
    }
`
