import { FC, useEffect, useState } from 'react'
import { StyledIndex } from './core/index.styles'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import MainLayout from '../../layouts/MainLayout'
import { Row, Col } from 'antd'
import { Text, ModalUpdateGoal, Button } from 'components'
import { FontFamilies, FontSizes, FontWeights } from 'shared/style/constants'
import { useDate } from 'components/DateLine/core/dateLine.hooks'
import { WeekBar } from 'components/WeekBar'
import { getProgram, selectDays } from 'pages/Program/core/programSlice'
import dayjs from 'dayjs'
import * as fr from 'dayjs/locale/fr'
import { getGoal, selectGoal, updateGoal } from './core/goalSlice'
import { v4 as uuidv4 } from 'uuid'
import { userSelector } from 'shared/user/userSlice'
import { Colors } from 'shared/style/constants'
import { ProgressGoal } from 'components/ProgressGoal'

const Index: FC = () => {
  dayjs.locale(fr)
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(userSelector)
  const { lastSevenDays } = useDate()
  const [modalUpdateGoalIsVisible, setModalUpdateGoalIsVisible] = useState(false)
  const program = useAppSelector(selectDays)
  const goal = useAppSelector(selectGoal)
  const [overallWeek, setOverallWeek] = useState<any>([])
  const [max, setMax] = useState<any>({ proteins: 0, fat: 0, carbs: 0, calories: 0 })
  const [average, setAverage] = useState<any>({ proteins: 0, fat: 0, carbs: 0, calories: 0 })

  useEffect(() => {
    if (user && user.programs && user.programs.length > 0) dispatch(getProgram({ id: user.programs[0] }))
    if (user && user.goal) dispatch(getGoal({ id: user.goal }))
  }, [user])

  useEffect(() => {
    if (Object.values(program).length > 0) {
      const dayWithOverall = lastSevenDays.map(day => {
        for (let i = 0; i < Object.values(program).length; i++) {
          if (Object.values(program)[i].key === day.completeDate) {
            return {
              ...Object.values(program)[i],
              ...day
            }
          }
        }
        return day
      })
      const maxProteins = Math.max(...Object.values(program).map(day => day.overall.proteins))
      const maxFat = Math.max(...Object.values(program).map(day => day.overall.fat))
      const maxCarbs = Math.max(...Object.values(program).map(day => day.overall.carbs))
      const maxCalories = Math.max(...Object.values(program).map(day => day.overall.calories))
      const completeDataSevenDays = lastSevenDays.map(item => item.completeDate)
      const averageCalories = (
        Object.values(program)
          .filter(day => day.overall && completeDataSevenDays.includes(day.key))
          .reduce((acc, day) => acc + day.overall.calories, 0) /
        Object.values(program).filter(day => day.overall && completeDataSevenDays.includes(day.key)).length
      ).toFixed(0)
      const averageProteins = (
        Object.values(program)
          .filter(day => day.overall && completeDataSevenDays.includes(day.key))
          .reduce((acc, day) => acc + day.overall.proteins, 0) /
        Object.values(program).filter(day => day.overall && completeDataSevenDays.includes(day.key)).length
      ).toFixed(0)
      const averageFat = (
        Object.values(program)
          .filter(day => day.overall && completeDataSevenDays.includes(day.key))
          .reduce((acc, day) => acc + day.overall.fat, 0) /
        Object.values(program).filter(day => day.overall && completeDataSevenDays.includes(day.key)).length
      ).toFixed(0)
      const averageCarbs = (
        Object.values(program)
          .filter(day => day.overall && completeDataSevenDays.includes(day.key))
          .reduce((acc, day) => acc + day.overall.carbs, 0) /
        Object.values(program).filter(day => day.overall && completeDataSevenDays.includes(day.key)).length
      ).toFixed(0)
      setAverage({ proteins: averageProteins, fat: averageFat, carbs: averageCarbs, calories: averageCalories })
      setMax({ proteins: maxProteins, fat: maxFat, carbs: maxCarbs, calories: maxCalories })
      setOverallWeek([...dayWithOverall])
    }
  }, [program])

  function getBottomPosition(key: 'fat' | 'proteins' | 'carbs') {
    const pourcentage = (goal[key] / max[key]) * 100 > 100 ? 105 : (goal[key] / max[key]) * 100
    return pourcentage + '%'
  }

  return (
    <MainLayout hideSidebar>
      <StyledIndex>
        <div className="container">
          <Row gutter={16}>
            <Col className="gutter-row" sm={24} md={24}>
              <div className="f-wrap f f-jc-space-b f-ai-center">
                <Text size={FontSizes.LARGE} font={FontFamilies.POPPINS} weight={FontWeights.BOLD}>
                  Les 7 derniers jours
                </Text>
                <Button
                  mainColor={Colors.PRIMARY}
                  secondaryColor={Colors.WHITE}
                  hover={true}
                  active={false}
                  onClick={() => setModalUpdateGoalIsVisible(true)}
                  className="params"
                >
                  Modifier mes objectifs
                </Button>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" sm={24} md={8}>
              <div className="graph">
                <div className="header-graph">
                  <Text className="title" size={FontSizes.LARGE} font={FontFamilies.POPPINS}>
                    Protéines
                  </Text>
                  {console.log(average.proteins)}
                  <div className="info">
                    <Text>Objectif: {goal.proteins}g</Text>
                    <Text>Moyenne: {average.proteins === 'NaN' ? '-' : average.proteins + 'g'}</Text>
                  </div>
                </div>
                <div className="graph-line">
                  {goal.proteins > 0 && <div style={{ bottom: getBottomPosition('proteins') }} className="line"></div>}
                  {overallWeek.map((day: any) => (
                    <WeekBar
                      type="proteins"
                      key={day.completeDate}
                      day={day.dayString}
                      value={day.overall?.proteins}
                      max={max.proteins}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col className="gutter-row" sm={24} md={8}>
              <div className="graph">
                <div className="header-graph">
                  <Text className="title" size={FontSizes.LARGE} font={FontFamilies.POPPINS}>
                    Glucides
                  </Text>
                  <div className="info">
                    <Text>Objectif: {goal.carbs}g</Text>
                    <Text>Moyenne: {average.carbs === 'NaN' ? '-' : average.carbs + 'g'}</Text>
                  </div>
                </div>
                <div className="graph-line">
                  {goal.carbs > 0 && <div style={{ bottom: getBottomPosition('carbs') }} className="line"></div>}
                  {overallWeek.map((day: any) => (
                    <WeekBar
                      type="carbs"
                      key={day.completeDate}
                      day={day.dayString}
                      value={day.overall?.carbs}
                      max={max.carbs}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col className="gutter-row" sm={24} md={8}>
              <div className="graph">
                <div className="header-graph">
                  <Text className="title" size={FontSizes.LARGE} font={FontFamilies.POPPINS}>
                    Lipides
                  </Text>
                  <div className="info">
                    <Text>Objectif: {goal.fat}g</Text>
                    <Text>Moyenne: {average.fat === 'NaN' ? '-' : average.fat + 'g'}</Text>
                  </div>
                </div>
                <div className="graph-line">
                  {goal.fat > 0 && <div style={{ bottom: getBottomPosition('fat') }} className="line"></div>}
                  {overallWeek.map((day: any) => (
                    <WeekBar
                      type="fat"
                      key={day.completeDate}
                      day={day.dayString}
                      value={day.overall?.fat}
                      max={max.fat}
                    />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Text className="mt-5 mb-4" size={FontSizes.LARGE} font={FontFamilies.POPPINS} weight={FontWeights.BOLD}>
                Statistique
              </Text>
              <ProgressGoal days={program} goal={goal} max={max} />
            </Col>
          </Row>
        </div>
        <ModalUpdateGoal
          goal={goal}
          onOk={data => {
            user &&
              dispatch(
                updateGoal({
                  goal: { _id: uuidv4(), author: user._id, ...data, weight: { value: data.weight, target: 0 } }
                })
              )
            setModalUpdateGoalIsVisible(false)
          }}
          modalIsVisible={modalUpdateGoalIsVisible}
          handleChangeVisible={setModalUpdateGoalIsVisible}
        />
      </StyledIndex>
    </MainLayout>
  )
}

export default Index
