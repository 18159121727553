import styled from 'styled-components'

import { Colors, COLORS } from 'shared/style/constants'

export const StyledFoodRow = styled['div']`
  background: #F5F5F5;
  box-shadow: 0px 2px 8px rgb(34 34 87 / 5%);
  border-radius: 10px;
  padding: 18px;
  margin-top: 20px;
  position: relative;

  .name {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > p:first-child {
      margin-bottom: 7px;
    }
    @media screen and (max-width: 600px) {
      margin: 10px 0;
    }
  }

  .border {
    width: 100%;
    height: 1px;
    margin-top: 18px;
    background: ${COLORS.grey2};
  }

  .delete-food {
    background: ${COLORS.DELETE};
    border-radius: 6px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
      position: absolute;
      right: 0px;
      top: 57px;
    }
  }

  .overall {
    display: flex;
    align-items: center;    
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
    }
  }

  .icon {
    min-height: 48px;
    min-width: 48px;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 12px;
    border-radius: 6px;
    background: #fff;
  }

  .input-number {
    max-width: 54px;
    min-width: 54px;
    margin-right: 12px;
    .ant-input-number-input {
      height: 46px;
    }
  }
  .ant-input-number-hover, .ant-input-number-focused, .ant-input-number:focus, .ant-input-number-focused, .ant-input-number-focused, .ant-input-number:hover, .ant-input-number-hover {
    border-color: ${COLORS['primary']};
    box-shadow: 0px 2px 8px rgb(34 34 87 / 5%);
  }

  .ant-select {
    height: 46px;
    margin-right: 12px;
    
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 46px;
  }

  .ant-select-selector > .ant-select-selection-item {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    line-height: 46px;
  }
  
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 46px;
  }

  .ant-select-selector {
  }

  .head {
    display: flex;
    flex:1;
    align-items: center;
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
      min-width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .ant-input-number-group-wrapper {
    margin-right: 20px;
    width: 270px;
    @media screen and (max-width: 600px) {
      width: 54%;
      margin-right: 0;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px;
  }

  .ant-input-number-group-addon {
    padding: 4px;
    max-height: 46px;
    max-width: 70px;
  }

  .ant-input-number-input {
    height: 46px;
  }

  @media screen and (max-width: 600px) {
    .group-overall {
      display: none;
    }
  }
`
