import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'

export const StyledProgressGoal = styled['div']`
    background: ${COLORS.grey1};
    padding: 24px;
    border-radius: 10px;
    @media screen and (max-width: 600px) {
        padding: 0;
        background: #fff;
    }

   .header {
       display: flex;
       align-items: center;
       justify-content: space-between;
       @media screen and (max-width: 600px) {
            flex-wrap: wrap;
       }
   }

   .day-name {
       font-family: "Poppins";
       font-weight: 500;
       font-size: 20px;
   }

   .filters {
       display: flex;
       .filter {
           padding: 10px;
           background: ${COLORS.grey2};
           border-radius: 5px;
           margin-left: 10px;
           color: #989898;
           cursor: pointer;
           font-weight: 600;
           @media screen and (max-width: 600px) {
            margin-left: 0px;
            margin-right: 10px;
            margin-top: 10px;
           }
        }
        .active {
           background: ${COLORS.primary};
           color: #fff;
       }
   }


   .round {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        border-radius: 50%;
        margin-right: 6px;
    }

    .line-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .progress {
        margin: 0 18px;
        border-radius: 10px;
        height: 24px;
        flex:1;
        position: relative;
    }
 
    .complete-line {
        position: absolute;
        border-radius: 10px;
        height: 24px;
        left:0;
        top:0;
        bottom: 0;
    }

    .percentage {
        font-family: "Poppins";
        font-size: 20px;
        width: 40px;
        text-align: right;
    }

    .box {
        padding: 24px;
        background: #fff;
        border-radius: 10px;
        margin-top: 14px;
        @media screen and (max-width: 600px) {
            background: ${COLORS.grey1};
        }
    }
    
    .calories {
        flex:1;
         .bg {
             background: #E74C3C30;
         }
         .complete-line {
             background: #E74C3C;
         }
     }
    .proteins {
        flex:1;
         .bg {
             background: #3498DB30;
         }
         .complete-line {
             background: #3498DB;
         }
     }
    .fat {
        flex:1;
         .bg {
             background: #1ABC9C30;
         }
         .complete-line {
             background: #1ABC9C;
         }
     }
    .carbs {
        flex:1;
         .bg {
             background: #9B59B630;
         }
         .complete-line {
             background: #9B59B6;
         }
     }

    .p {
        background: #3498DB;
    }
    .c {
        background: #E74C3C;
    }
    .l {
        background: #1ABC9C;
    }
    .g {
        background: #9B59B6;
    }

`
