import { FC } from 'react'
import { useAppDispatch, useAppSelector } from 'shared/hooks/store'
import { createFirstMeal, createMeal, selectActiveDay } from 'pages/Program/core/programSlice'

import { StyledCreateMeal } from './core/createMeal.styles'
import { IProps } from './core/createMeal.types'
import { Plus } from 'react-feather'
import { Text } from 'components/Text'
import { FontSizes } from 'shared/style/constants'
import { useDrop } from 'react-dnd'
import { ItemTypes } from 'components/SearchRow/core/searchRow.types'

export const CreateMeal: FC<IProps> = props => {
  const dispatch = useAppDispatch()
  const journey = useAppSelector(selectActiveDay)
  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: ItemTypes.FOOD,
      drop: (data: any, monitor) => {
        const didDrop = monitor.didDrop()
        if (didDrop) {
          return
        }
        dispatch(createFirstMeal({ food: data, name: generateName() }))
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true })
      })
    }),
    [journey]
  )
  function generateName() {
    const numberMeals = Object.values(journey.meals).length
    switch (numberMeals) {
      case 0:
        return 'Petit-déjeuner'
      case 1:
        return 'Déjeuner'
      case 2:
        return 'Collation 1'
      case 3:
        return 'Dîner'
      case 4:
        return 'Collation 2'
      default:
        return 'Extra'
    }
  }

  return (
    <StyledCreateMeal
      ref={drop}
      isOverCurrent={isOverCurrent}
      onClick={() => dispatch(createMeal({ name: generateName() }))}
    >
      <div className="round">
        <Plus color="#fff" />
      </div>
      <Text size={FontSizes.MEDIUM}>Ajouter un repas</Text>
    </StyledCreateMeal>
  )
}
