import styled from 'styled-components'

import { COLORS } from 'shared/style/constants'

export const StyledTag = styled['div']`
  display: flex;
  align-items: center;

  .round {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 50%;
    margin-right: 6px;
  }

  .value {
    font-weight: 500;
    color:${COLORS.black};
  }

  .p {
    background: #3498DB;
  }
  .c {
    background: #E74C3C;
  }
  .l {
    background: #1ABC9C;
  }
  .g {
    background: #9B59B6;
  }
`
