import styled from 'styled-components'

import {
  COLORS,
  FONT_FAMILIES,
  FONT_SIZES,
  FONT_WEIGHTS,
  SPACINGS,
} from 'shared/style/constants'

import { IProps } from './textInput.types'

export const StyledTextInput = styled('div')<IProps>`
  .input {
    position: relative;
    ${({ iconPosition, icon }) =>
    icon &&
      (iconPosition && iconPosition === 'right'
        ? `padding-right: ${SPACINGS[5]};`
        : `padding-left: ${SPACINGS[5]};`)}

    input {
      font-size: ${FONT_SIZES['base']};
      font-family: ${FONT_FAMILIES['inter']};
      color: ${COLORS['black']};
      font-weight: ${FONT_WEIGHTS['medium']};
      position: relative;
      background: none;
      border: 1px solid #E8E8EF;
      flex: 1 0 auto;
      outline: none;
      background: ${COLORS.grey1};
      border-radius: 5px;
      font-size: 16px;
      padding: 10px;

      &:placeholder {
        background: ${COLORS['grey1']};
      }
    }

    .icon {
      ${({ icon }) => !icon && 'display: none;'}
      position: absolute;
      ${({ iconPosition }) =>
    iconPosition && iconPosition === 'right' ? 'right: 0;' : 'left: 0;'}
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
`
